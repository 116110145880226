#btn_full_grp {
  position: absolute;
  width: 129px;
  height: 55px;
  left: 87px;
  top: 273px;
  overflow: visible;
}
#Group_8 {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 4px;
  top: 20px;
  overflow: visible;
}
#Ellipse_2 {
  fill: rgba(255, 255, 255, 1);
}
.Ellipse_2 {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#play-buttton_img_task_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 5px;
  top: 5.5px;
  overflow: visible;
}
#reset_btn_leave {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 93px;
  top: 20px;
  overflow: visible;
}
#bdr_reset_leave {
  fill: rgba(255, 255, 255, 1);
}
.bdr_reset_leave {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#stop-button_img_leave {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 5px;
  top: 6px;
  overflow: visible;
}
#Create_lbl_ {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 44px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#Reset_lbl {
  left: 93px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 37px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#approved_full_grp {
  position: absolute;
  width: 94px;
  height: 47px;
  left: 205px;
  top: 7px;
  overflow: visible;
}
#bg_design_app {
  fill: rgba(255, 255, 255, 1);
}
.bg_design_app {
  position: absolute;
  overflow: visible;
  width: 94px;
  height: 47px;
  left: 0px;
  top: 0px;
}
#Approved_lbl_app {
  left: 13px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 68px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(38, 37, 37, 1);
  text-decoration: underline;
}
#leave_rec_app {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 4px;
  top: 25px;
  overflow: visible;
}
#lbl_leav_app {
  left: 28px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#holiday_rec_app {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 53px;
  top: 25px;
  overflow: visible;
}
#holi_lbl_app {
  left: 77px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#from_full_gro_leave {
  position: absolute;
  width: 127px;
  height: 96px;
  left: 17px;
  top: 55px;
  overflow: visible;
}
#from_bdr_input_1_eave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.from_bdr_input_1_eave {
  position: absolute;
  overflow: visible;
  width: 127px;
  height: 34px;
  left: 0px;
  top: 20px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#From_lbl_1_leave {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 38px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(37, 37, 37, 1);
}
#halfs_grp_leave {
  position: absolute;
  width: 89px;
  height: 34px;
  left: 0px;
  top: 62px;
  overflow: visible;
}
#First_Half_mob {
  left: 18px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 52px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Second_Half_mob {
  left: 20px;
  top: 17px;
  position: absolute;
  overflow: visible;
  width: 70px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#chkbx_11 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_11_mob {
  position: absolute;
  overflow: visible;
  width: 14px;
  height: 14px;
  left: 0px;
  top: 1px;
}
#chkbx_12_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_12_mob {
  position: absolute;
  overflow: visible;
  width: 14px;
  height: 14px;
  left: 0px;
  top: 19px;
}
#to_full_grp_leave {
  position: absolute;
  width: 127px;
  height: 97px;
  left: 162px;
  top: 54px;
  overflow: visible;
}
#to_bdr_input_2_leave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.to_bdr_input_2_leave {
  position: absolute;
  overflow: visible;
  width: 127px;
  height: 34px;
  left: 0px;
  top: 21px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#To_lbl_2_leav {
  left: 3px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 17px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(37, 37, 37, 1);
}
#n_half_full_grp {
  position: absolute;
  width: 89px;
  height: 34px;
  left: 0px;
  top: 63px;
  overflow: visible;
}
#First_Half_leave {
  left: 18px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 52px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Second_Half_leave {
  left: 20px;
  top: 17px;
  position: absolute;
  overflow: visible;
  width: 70px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#chkbx_11_to {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_11_to {
  position: absolute;
  overflow: visible;
  width: 14px;
  height: 14px;
  left: 0px;
  top: 1px;
}
#chkbx_12_to {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_12_to {
  position: absolute;
  overflow: visible;
  width: 14px;
  height: 14px;
  left: 0px;
  top: 19px;
}
#eligible_full_grp {
  position: absolute;
  width: 94px;
  height: 47px;
  left: 7px;
  top: 7px;
  overflow: visible;
}
#eli_bg_ {
  fill: rgba(255, 255, 255, 1);
}
.eli_bg_ {
  position: absolute;
  overflow: visible;
  width: 94px;
  height: 47px;
  left: 0px;
  top: 0px;
}
#Approved_lbl_eli {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(38, 37, 37, 1);
  text-decoration: underline;
}
#leave_rec_eli {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 4px;
  top: 25px;
  overflow: visible;
}
#lbl_leav_eli {
  left: 28px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#holiday_rec_eli {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 55px;
  top: 25px;
  overflow: visible;
}
#holi_lbl_eli {
  left: 79px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#availed_full_grp {
  position: absolute;
  width: 94px;
  height: 47px;
  left: 106px;
  top: 7px;
  overflow: visible;
}
#availed_bg_leave {
  fill: rgba(255, 255, 255, 1);
}
.availed_bg_leave {
  position: absolute;
  overflow: visible;
  width: 94px;
  height: 47px;
  left: 0px;
  top: 0px;
}
#availed_lbl_leave {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 51px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(38, 37, 37, 1);
  text-decoration: underline;
}
#leave_rec_img {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 4px;
  top: 25px;
  overflow: visible;
}
#lbl_leave {
  left: 28px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#holiday_rec_leave {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 53px;
  top: 25px;
  overflow: visible;
}
#holi_lbl_leave {
  left: 77px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#leave_type_grp_mob {
  position: absolute;
  width: 272px;
  height: 57px;
  left: 17px;
  top: 160px;
  overflow: visible;
}
#leave_reason {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.leave_reason {
  position: absolute;
  overflow: visible;
  width: 127px;
  height: 36px;
  left: 0px;
  top: 21px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#leave_type_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 74px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#approver_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.approver_input_mob {
  position: absolute;
  overflow: visible;
  width: 127px;
  height: 36px;
  left: 145px;
  top: 21px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#leave_days_grp_mob {
  position: absolute;
  width: 116px;
  height: 35px;
  left: 93px;
  top: 226px;
  overflow: visible;
}
#Leave_Days_lbl_mob {
  left: 0px;
  top: 8px;
  position: absolute;
  overflow: visible;
  width: 76px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#no_grp {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 81px;
  top: 0px;
  overflow: visible;
}
#rec_bdr_leave {
  fill: rgba(255, 255, 255, 1);
}
.rec_bdr_leave {
  filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#no_of_days_leave {
  left: 14px;
  top: 7px;
  position: absolute;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#noti_grp_leave {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 266px;
  top: 4px;
  overflow: visible;
}
#leave_btn {
  fill: rgba(255, 255, 255, 1);
}
.leave_btn {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 58px;
  height: 58px;
  left: 0px;
  top: 0px;
}

#grid_info_overall_grp {
  position: relative;
  width: 282px;
  height: 97px;
  left: 10px;
  top: 7px;
  overflow: visible;
  margin-bottom: 10px;
}
#grid_bg_leave {
  fill: rgba(255, 255, 255, 1);
}
.grid_bg_leave {
  position: absolute;
  overflow: visible;
  width: 282px;
  height: 97px;
  left: 0px;
  top: 0px;
}
#leave1 {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 165px;
  top: 45px;
  overflow: visible;
}
#date_grid {
  left: 179px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#n__lbl_grid {
  left: 192px;
  top: 49px;
  position: absolute;
  overflow: visible;
  width: 8px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#lbl_name_grid {
  left: 9px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 121px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#date_mob_grid {
  left: 196px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 78px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#n_023-04-21_date_grid {
  left: 28px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#clock_img_grid {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 27px;
  overflow: visible;
}
#comments1_grid {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 71px;
  overflow: visible;
}
#FROM_grid {
  left: 106px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 38px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Out_lbl__grid {
  left: 253px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 24px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#applied_lbl_grid {
  left: 30px;
  top: 48px;
  position: absolute;
  overflow: visible;
  width: 45px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#clock_img_mob_leave {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 161px;
  top: 27px;
  overflow: visible;
}
#clipboard_img_grid {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 48px;
  overflow: visible;
}
#Eid-Ul-Fitr_lbl_mob {
  left: 29px;
  top: 70px;
  position: absolute;
  overflow: visible;
  width: 59px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Line_1_underline_leave {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_1_underline_leave {
  overflow: visible;
  position: absolute;
  width: 266px;
  height: 1px;
  left: 8.5px;
  top: 18.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#date_lbl_grid_mob {
  left: 179px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#leave_img_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 165px;
  top: 45px;
  overflow: visible;
}
#n__lbl_mob {
  left: 192px;
  top: 49px;
  position: absolute;
  overflow: visible;
  width: 8px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#leave_reason {
  position: absolute;
  width: 298px;
  height: 107px;
  left: 41px;
  top: 306px;
  overflow: visible;
  fill: rgba(255, 255, 255, 1);
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#add_leave {
  top: 413px;
  left: 161px;
  position: absolute;
}

/* leaves popup */

/* #pop_up_leave {
  position: absolute;
  width: 288px;
  height: 697px;
  left: 42px;
  top: 31px;
  overflow: scroll;
} */
#pop_up_leave {
  position: absolute;
  width: 80%; /* Adjust the width as needed */
  height: 80vh; /* Adjust the height as needed */
  left: 10%; /* Adjust the left position as needed */
  top: 10%; /* Adjust the top position as needed */
  bottom: 10%; /* Adjust the bottom position as needed */
  overflow: scroll;
}
#close_popup_mob_leave {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 145px;
  top: -3px;
  overflow: visible;
  cursor: pointer;
}
#over_all_pop_leave_grp {
  position: absolute;
  width: 288px;
  height: 697px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#bg_design_mob_leave {
  position: absolute;
  width: 288px;
  height: 697px;
  left: 0px;
  top: 50px;
  overflow: visible;
}
#editleave_bdr_popleave_m {
  fill: url(#editleave_bdr_popleave_m);
}
.editleave_bdr_popleave_m {
  position: absolute;
  overflow: visible;
  width: 288px;
  height: 51px;
  left: 0px;
  top: 0px;
}
#Edit_Leave_lbl_pop_leave {
  left: 104px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 81px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#leave_days_grp_leave {
  position: absolute;
  width: 197px;
  height: 42px;
  left: 38px;
  top: 329px;
  overflow: visible;
}
#bdr_leaves_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_leaves_mob {
  position: absolute;
  overflow: visible;
  width: 42px;
  height: 42px;
  left: 155px;
  top: 0px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
}
#no_of_days_lb_leave {
  left: 166px;
  top: 8px;
  position: absolute;
  overflow: visible;
  width: 11px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: rgba(37, 37, 37, 1);
}
#No_of_Days_lbl_leave {
  left: 0px;
  top: 12px;
  position: absolute;
  overflow: visible;
  width: 135px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#btn_grp_input_grp {
  position: absolute;
  width: 261px;
  height: 681px;
  left: 21px;
  top: 61px;
  overflow: scroll;
}

#leave_type_grp_mob_popup {
  position: absolute;
  width: 232px;
  height: 62px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#leave_dropdown_inputpop_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.leave_dropdown_inputpop_mob {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
}
#leave_type_leave {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 74px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#approver_name_grp_mob {
  position: absolute;
  width: 232px;
  height: 62px;
  left: 0px;
  top: 72px;
  overflow: visible;
}
#approver_input_leave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.approver_input_leave {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#approver_name_leave {
  left: 2px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 106px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#from_date_grp_mob_popup {
  position: absolute;
  width: 110px;
  height: 62px;
  left: 0px;
  top: 144px;
  overflow: visible;
}
#From_lbl_date_leave {
  left: 2px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 35px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#from_bdr_input_pop_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.from_bdr_input_pop_mob {
  position: absolute;
  overflow: visible;
  width: 110px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
}
#todate_grp {
  position: absolute;
  width: 112px;
  height: 62px;
  left: 120px;
  top: 144px;
  overflow: visible;
}
#Group_3 {
  position: absolute;
  width: 112px;
  height: 62px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#To_lbl_date_leave {
  left: 3px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 16px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#to_bdr_input_box_leave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.to_bdr_input_box_leave {
  position: absolute;
  overflow: visible;
  width: 112px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
}
#check_box_4_grp_leave {
  position: absolute;
  width: 66px;
  height: 16px;
  left: 0px;
  top: 216px;
  overflow: visible;
}
#checkbox_4_leave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.checkbox_4_leave {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#First_Half_4_lbl_leave {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 48px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#check_box_2_grp_leave {
  position: absolute;
  width: 66px;
  height: 16px;
  left: 0px;
  top: 242px;
  overflow: visible;
}
#bdr_check_box_2_leave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_check_box_2_leave {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#First_Half_2leave_ {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 48px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#check_box_31_grp_leave {
  position: absolute;
  width: 83px;
  height: 16px;
  left: 83px;
  top: 216px;
  overflow: visible;
}
#checkbox_31_input_leave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.checkbox_31_input_leave {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#Second_Half_31_lbl_leave {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#check_box_17_grp_leave {
  position: absolute;
  width: 83px;
  height: 16px;
  left: 83px;
  top: 242px;
  overflow: visible;
}
#checkbox_button_leave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.checkbox_button_leave {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#Second_Half_text_leave {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#leave_reason_grp_ {
  position: absolute;
  width: 232px;
  height: 100px;
  left: 0px;
  top: 320px;
  overflow: visible;
}
#leave_comments_grp_ {
  position: absolute;
  width: 232px;
  height: 100px;
  left: 0px;
  top: 421px;
  overflow: visible;
}
#leave_reason_leave {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.leave_reason_leave {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 80px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 4px;
}
#leave_reason_lbl_pop_leave {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 90px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#btn_grp_first_leave {
  position: absolute;
  width: 133px;
  height: 50px;
  left: 52px;
  top: 531px;
  overflow: visible;
  border-radius: 10px;
}
#btn_edit_leave {
  fill: rgba(162, 228, 197, 1);
}
.btn_edit_leave {
  position: absolute;
  overflow: visible;
  width: 133px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Edit_Leave_pop_lbl_leave {
  left: 33px;
  top: 16px;
  position: absolute;
  overflow: visible;
  width: 68px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
