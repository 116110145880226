.mediaViewInfo {
  --web-view-name: popup leave;
  --web-view-id: popup_leave;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: popup_leave;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#close_ {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 388px;
  top: 6px;
  overflow: visible;
  cursor: pointer;
}
#popup_leave {
  position: fixed;
  width: 424px;
  height: 510px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  overflow: scroll;
  --web-view-name: popup leave;
  --web-view-id: popup_leave;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#overall_grp_leave_pop {
  position: absolute;
  width: 425px;
  height: 510px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#grp_bg_desgin_grp_pop {
  position: absolute;
  width: 425px;
  height: 510px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#bg_desgin_full_pop {
  position: absolute;
  width: 425px;
  height: 605px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#editleave_bdr_pop_l {
  fill: url(#editleave_bdr_pop_l);
}
.editleave_bdr_pop_l {
  position: absolute;
  overflow: visible;
  width: 425px;
  height: 51px;
  left: 0px;
  top: 0px;
}
#Edit_Leave_lbl_pop {
  left: 168px;
  top: 12px;
  position: absolute;
  overflow: visible;
  width: 90px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(37, 37, 37, 1);
}
#Edit_leave_inner_grp {
  position: absolute;
  width: 389px;
  height: 424px;
  left: 18px;
  top: 56px;
  overflow: visible;
}
#From_lbl_date {
  left: 3px;
  top: 73px;
  position: absolute;
  overflow: visible;
  width: 40px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#To_lbl_date {
  left: 205px;
  top: 73px;
  position: absolute;
  overflow: visible;
  width: 18px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#leave_dropdown_inputpop {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.leave_dropdown_inputpop {
  position: absolute;
  overflow: visible;
  width: 188px;
  height: 42px;
  left: 0px;
  top: 22px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
}
#from_bdr_input_pop {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.from_bdr_input_pop {
  position: absolute;
  overflow: visible;
  width: 187px;
  height: 42px;
  left: 1px;
  top: 95px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
}
#to_bdr_input_box_pop {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.to_bdr_input_box_pop {
  position: absolute;
  overflow: visible;
  width: 187px;
  height: 42px;
  left: 202px;
  top: 95px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
}
#check_box_4_grp_pop {
  position: absolute;
  width: 66px;
  height: 16px;
  left: 6px;
  top: 147px;
  overflow: visible;
}
#checkbox_4_pop_btn {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.checkbox_4_pop_btn {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#First_Half_4_lbl_pop {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 48px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#check_box_2_grp_pop {
  position: absolute;
  width: 66px;
  height: 16px;
  left: 209px;
  top: 147px;
  overflow: visible;
}
#bdr_check_box_2_pop {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_check_box_2_pop {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#First_Half_2_pop {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 48px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#leave_days_grp_pop {
  position: absolute;
  width: 212px;
  height: 42px;
  left: 88px;
  top: 190px;
  overflow: visible;
}
#bdr_leaves {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_leaves {
  position: absolute;
  overflow: visible;
  width: 42px;
  height: 42px;
  left: 170px;
  top: 0px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
}
#no_of_days_lb_pop {
  left: 186px;
  top: 8px;
  position: absolute;
  overflow: visible;
  width: 12px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(37, 37, 37, 1);
}
#No_of_Days_lbl_pop {
  left: 0px;
  top: 8px;
  position: absolute;
  overflow: visible;
  width: 159px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(37, 37, 37, 1);
}
#leave_reason_input_box {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.leave_reason_input_box {
  position: absolute;
  overflow: visible;
  width: 388px;
  height: 80px;
  left: 0px;
  top: 259px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 4px;
}
.leave_approver_remarks {
  position: absolute;
  overflow: visible;
  width: 388px;
  height: 80px;
  left: 0px;
  top: 375px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 4px;
}
#check_box_31_grp {
  position: absolute;
  width: 83px;
  height: 16px;
  left: 89px;
  top: 147px;
  overflow: visible;
}
#checkbox_31_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.checkbox_31_input {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#Second_Half_31_lbl {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#check_box_17_grp {
  position: absolute;
  width: 83px;
  height: 16px;
  left: 300px;
  top: 147px;
  overflow: visible;
}
#checkbox_button {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.checkbox_button {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#Second_Half_text {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#approver_input_box {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.approver_input_box {
  position: absolute;
  overflow: visible;
  width: 187px;
  height: 42px;
  left: 201px;
  top: 22px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#btn_grp_first:hover {
  left: 111px;
  top: 497px;
}
#btn_grp_first {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 103px;
  top: 492px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#btn_grp_first_approval {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 25px;
  top: 492px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#btn_grp_second_approval {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 194px;
  top: 492px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
.leave_approver_remarks_button {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 103px;
  top: 375px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 4px;
}
#btn_edit_pop_leave {
  fill: rgba(162, 228, 197, 1);
}
.btn_edit_pop_leave {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Edit_Leave_pop_lbl {
  left: 43px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 77px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#leave_type_lbl {
  left: 3px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 83px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#approver_name_lbl {
  left: 203px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 120px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#leave_reason_lbl_pop {
  left: 0px;
  top: 237px;
  position: absolute;
  overflow: visible;
  width: 102px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}

#approver_remarks {
  left: 0px;
  top: 349px;
  position: absolute;
  overflow: visible;
  width: 102px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
