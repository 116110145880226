#mid_changes_grp_full {
  position: absolute;
  width: 831px;
  height: 266.348px;
  left: 27px;
  top: 0px;
  overflow: visible;
}
#comment_box_1 {
  fill: rgba(255, 255, 255, 1);
}
.comment_box_1 {
  position: absolute;
  overflow: visible;
  width: 371px;
  height: 99px;
  left: 230px;
  top: 87px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#app_res_grp {
  position: absolute;
  width: 382.862px;
  height: 80.348px;
  left: 235px;
  top: 186px;
  overflow: visible;
}
#Start_Session_lbl_2 {
  left: 2px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 44px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Session_End_lbl_2 {
  left: 341px;
  top: 1px;
  position: absolute;
  overflow: visible;
  width: 41px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#bdr_22 {
  fill: rgba(255, 255, 255, 1);
}
.bdr_22 {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 0px;
  top: 29.374px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_11 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_11 {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 4px;
  top: 33.374px;
}
#play-buttton_img_2 {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 9.216px;
  top: 37.798px;
  overflow: visible;
}
#link_up_2 {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 12.917px;
  top: 22px;
  overflow: visible;
}
#link_down_2 {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 13.126px;
  top: 63.6px;
  overflow: visible;
}
#left_link_up_2_cb {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up_2_cb {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 21.641px;
  top: 33.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down_2_cc {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down_2_cc {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 16.503px;
  top: 67.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#bdr_back_2 {
  fill: rgba(255, 255, 255, 1);
}
.bdr_back_2 {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 339.862px;
  top: 29.374px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_2_ce {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_2_ce {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 343.862px;
  top: 33.374px;
}
#stop-button_img_2 {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 350.362px;
  top: 38.874px;
  overflow: visible;
}
#link1_mid_top_2 {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 352.779px;
  top: 22px;
  overflow: visible;
}
#link1_mid_down_2 {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 352.988px;
  top: 63.6px;
  overflow: visible;
}
#left_link_up_2_ci {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up_2_ci {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 361.502px;
  top: 33.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down_2_cj {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down_2_cj {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 356.364px;
  top: 67.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#holidays_grp {
  position: absolute;
  width: 141px;
  height: 191px;
  left: 0px;
  top: 16px;
  overflow: visible;
}
#Eligible_lbl {
  left: 41px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
  text-decoration: underline;
}
#leave_img_eli {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0px;
  top: 27px;
  overflow: visible;
}
#leave_no_lbl {
  left: 35px;
  top: 31px;
  position: absolute;
  overflow: visible;
  width: 19px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#holiday_img_3 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 88px;
  top: 27px;
  overflow: visible;
}
#eligible_lbl_leave {
  left: 123px;
  top: 31px;
  position: absolute;
  overflow: visible;
  width: 19px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Availed_lbl {
  left: 41px;
  top: 67px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: rgba(38, 37, 37, 1);
  text-decoration: underline;
}
#avail_img_2 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0px;
  top: 94px;
  overflow: visible;
}
#avail_lbl_2 {
  left: 35px;
  top: 98px;
  position: absolute;
  overflow: visible;
  width: 19px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#avail_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 88px;
  top: 94px;
  overflow: visible;
}
#availed_lbl_holi {
  left: 123px;
  top: 98px;
  position: absolute;
  overflow: visible;
  width: 19px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Approved_lbl {
  left: 31px;
  top: 134px;
  position: absolute;
  overflow: visible;
  width: 77px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: rgba(38, 37, 37, 1);
  text-decoration: underline;
}
#arpoved_grp {
  position: absolute;
  width: 141px;
  height: 30px;
  left: 0px;
  top: 161px;
  overflow: visible;
}
#leave_grp {
  position: absolute;
  width: 53px;
  height: 30px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#leave_rec {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#lbl_leav {
  left: 35px;
  top: 4px;
  position: absolute;
  overflow: visible;
  width: 19px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#holiday_grp {
  position: absolute;
  width: 53px;
  height: 30px;
  left: 88px;
  top: 0px;
  overflow: visible;
}
#holiday_rec {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#holi_lbl {
  left: 35px;
  top: 4px;
  position: absolute;
  overflow: visible;
  width: 19px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#leave_days_grp {
  position: absolute;
  width: 116px;
  height: 35px;
  left: 676px;
  top: 20px;
  overflow: visible;
}
#Leave_Days_lbl {
  left: 0px;
  top: 8px;
  position: absolute;
  overflow: visible;
  width: 76px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#no_grp {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 81px;
  top: 0px;
  overflow: visible;
}
#rec_bg {
  fill: rgba(255, 255, 255, 1);
}
.rec_bg {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 0px;
  top: 0px;
}
#no_of_days {
  left: 15px;
  top: 8px;
  position: absolute;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#from_date_grp {
  position: absolute;
  width: 190px;
  height: 81px;
  left: 215px;
  top: 0px;
  overflow: visible;
}
#from_bdr_input_1 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.from_bdr_input_1 {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 34px;
  left: 5px;
  top: 19px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#From_lbl_1 {
  left: 5px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 38px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(37, 37, 37, 1);
}
#time_grp {
  position: absolute;
  width: 190px;
  height: 20px;
  left: 0px;
  top: 61px;
  overflow: visible;
}
#First_Half {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 60px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#Second_Half {
  left: 110px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 81px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#chkbx_11 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_11 {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 0px;
  top: 2px;
}
#chkbx_12 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_12 {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 90px;
  top: 2px;
}
#to_date_grp {
  position: absolute;
  width: 190px;
  height: 81px;
  left: 439px;
  top: 0px;
  overflow: visible;
}
#to_bdr_input_2_dh {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.to_bdr_input_2_dh {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 34px;
  left: 0px;
  top: 19px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#To_lbl_2_di {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 17px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(37, 37, 37, 1);
}
#First_Half_2 {
  left: 19px;
  top: 61px;
  position: absolute;
  overflow: visible;
  width: 60px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#Second_Half_2 {
  left: 110px;
  top: 61px;
  position: absolute;
  overflow: visible;
  width: 81px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#chkbx_21 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_21 {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 0px;
  top: 63px;
}
#chkbx_22 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_22 {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 90px;
  top: 63px;
}
#leave_type_grp {
  position: absolute;
  width: 156px;
  height: 111px;
  left: 675px;
  top: 71px;
  overflow: visible;
}
#leave_reason {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.leave_reason {
  position: absolute;
  overflow: visible;
  width: 156px;
  height: 36px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#leave_type_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 83px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#approver_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.approver_input {
  position: absolute;
  overflow: visible;
  width: 156px;
  height: 36px;
  left: 0px;
  top: 75px;
  font-size: 15px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
