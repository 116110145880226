#btn_lbl_grp_mob {
  position: absolute;
  width: 82px;
  height: 55px;
  left: 110px;
  top: 273px;
  overflow: visible;
}
#btn_grp_holi_mob {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 24px;
  top: 20px;
  overflow: visible;
}
#bdr_holi_btn_mob {
  fill: rgba(255, 255, 255, 1);
}
.bdr_holi_btn_mob {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#play-buttton_img_holi_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 5px;
  top: 5.5px;
  overflow: visible;
}
#Add_Holiday_holi_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 83px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#from_date_holi_mob {
  fill: rgba(255, 255, 255, 1);
}
.from_date_holi_mob {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 34px;
  left: -70px;
  top: -217px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#occasion_input_holi_mob {
  fill: rgba(255, 255, 255, 1);
}
.occasion_input_holi_mob {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 87px;
  left: 79px;
  top: 329px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#From_holi_lbl {
  left: 45px;
  top: 31px;
  position: absolute;
  overflow: visible;
  width: 35px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#Occation_holi_lbl {
  left: 42px;
  top: 102px;
  position: absolute;
  overflow: visible;
  width: 60px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
.occasion_input_holi_mob {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 87px;
  left: 41px;
  top: 129px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}

/*holidays grid*/
#grid_holi_mob {
  fill: rgba(255, 255, 255, 1);
}
.grid_holi_mob {
  position: absolute;
  overflow: visible;
  width: 282px;
  height: 189px;
  left: 47px;
  top: 559px;
}
#Mohammed_Affan_lbl {
  left: 55.25px;
  top: 559px;
  position: absolute;
  overflow: visible;
  width: 121px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#date_holi_mob {
  left: 242.25px;
  top: 559px;
  position: absolute;
  overflow: visible;
  width: 78px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#Line_1_underline {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_1_underline {
  overflow: visible;
  position: absolute;
  width: 266px;
  height: 1px;
  left: 54.75px;
  top: 577.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#card_1_holi {
  position: relative;
  width: 266px;
  height: 50px;
  left: 10.25px;
  top: 13px;
  overflow: visible;
  margin: 5px;
}
#card_1_bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.card_1_bdr {
  position: absolute;
  overflow: visible;
  width: 266px;
  height: 44px;
  left: 0px;
  top: 0px;
}
#ChristmasSun_Dec_25_2022 {
  left: 11px;
  top: 2px;
  position: absolute;
  overflow: visible;
  width: 196px;
  white-space: normal;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#chkbx_holi_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_holi_mob {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 224px;
  top: 14px;
}
