.mediaViewInfo {
  --web-view-name: add new employee per;
  --web-view-id: add_new_employee_per;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: add_new_employee_per;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#add_new_employee_per {
  position: absolute;
  width: 375px;
  height: 852px;
  background-color: rgba(226, 255, 217, 1);
  overflow: hidden;
  --web-view-name: add new employee per;
  --web-view-id: add_new_employee_per;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}

#bg_pic_per {
  position: absolute;
  width: 375px;
  height: 1093px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#bg_design_circle {
  fill: rgba(233, 255, 230, 1);
}
.bg_design_circle {
  position: absolute;
  overflow: visible;
  width: 462px;
  height: 459px;
  left: -43px;
  top: -366px;
}
#profile_pic_pfp {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 133px;
  top: 26px;
  overflow: visible;
}
#input_and_tab_design {
  position: absolute;
  width: 378.002px;
  height: 1035.085px;
  left: -0.002px;
  top: 141.74px;
  overflow: visible;
}
#design_tabs {
  fill: rgba(233, 255, 230, 1);
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.5px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.design_tabs {
  overflow: visible;
  position: absolute;
  width: 377.71px;
  height: 1035.613px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Scroll_Group_emp_per {
  mix-blend-mode: normal;
  position: absolute;
  width: 378px;
  height: 532px;
  left: 0.002px;
  top: 67.26px;
  overflow-x: hidden;
  overflow-y: scroll;
}
#all_input_grp_with_btn {
  position: absolute;
  width: 250px;
  height: 760px;
  left: 63px;
  top: 0px;
  overflow: visible;
}
#name_grp_input {
  position: absolute;
  width: 250px;
  height: 68px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#name_bd {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.name_bd {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 45px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#name_lbl_input {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 46px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#mobile_number_grp {
  position: absolute;
  width: 250px;
  height: 72px;
  left: 0px;
  top: 160px;
  overflow: visible;
}
#mob_no_bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mob_no_bdr {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#mob_number_grp {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 120px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#email_id_grp {
  position: absolute;
  width: 250px;
  height: 72px;
  left: 0px;
  top: 78px;
  overflow: visible;
}
#email_input_ {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.email_input_ {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#Email_profile_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 62px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Gender_grp_input {
  position: absolute;
  width: 250px;
  height: 72px;
  left: 0px;
  top: 242px;
  overflow: visible;
}
#Gender_profile_name {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 56px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#gen_bdr_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.gen_bdr_input {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#DOB_grp_ {
  position: absolute;
  width: 250px;
  height: 71px;
  left: 0px;
  top: 324px;
  overflow: visible;
}
#dob_bdr_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.dob_bdr_input {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 22px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#DOB_profile_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 35px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Aadhar_number_grp {
  position: absolute;
  width: 250px;
  height: 72px;
  left: 0px;
  top: 405px;
  overflow: visible;
}
#aadhar_bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.aadhar_bdr {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#aadhar_lvl_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 88px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Address_grp {
  position: absolute;
  width: 250px;
  height: 109px;
  left: 0px;
  top: 651px;
  overflow: visible;
}
#address_bdr_ {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.address_bdr_ {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 86px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#address_lbl_port {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#ECP_Grp {
  position: absolute;
  width: 250px;
  height: 72px;
  left: 0px;
  top: 487px;
  overflow: visible;
}
#ECG_bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ECG_bdr {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#ECG_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 202px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#ECN_grp {
  position: absolute;
  width: 250px;
  height: 72px;
  left: 0px;
  top: 569px;
  overflow: visible;
}
#ECN_bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ECN_bdr {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#ECN_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 213px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#btn_next_page {
  fill: rgba(162, 228, 197, 1);
}
.btn_next_page {
  position: absolute;
  overflow: visible;
  width: 132px;
  height: 50px;
  left: 122.002px;
  top: 626.26px;
}
#Next_Page_lbl_btn {
  top: 641.26px;
  position: relative;
  overflow: visible;
  /* width: 68px; */
  text-align: center;
  white-space: nowrap;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#top_tab_names_ {
  position: absolute;
  width: 235px;
  height: 22px;
  left: 54px;
  top: 179px;
  overflow: visible;
}
#personal_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 64px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Office_info_lvl {
  left: 157px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  --web-animation: fadein undefineds undefined;
  --web-action-type: page;
  --web-action-target: add_new_emplyee_off.html;
  cursor: pointer;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
/*nnnjnnnnnn*/
.mediaViewInfo {
  --web-view-name: add new emplyee off;
  --web-view-id: add_new_emplyee_off;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: add_new_emplyee_off;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#add_new_emplyee_off {
  position: absolute;
  width: 100%;
  height: 852px;
  background-color: rgba(226, 255, 217, 1);
  overflow: hidden;
  --web-view-name: add new emplyee off;
  --web-view-id: add_new_emplyee_off;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}

#bg_pic {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#bg_deisgn_emp_off {
  fill: rgba(233, 255, 230, 1);
}
.bg_deisgn_emp_off {
  position: absolute;
  overflow: visible;
  width: 462px;
  height: 459px;
  left: -43px;
  top: -366px;
}
#profile_pic_emp_off {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 133px;
  top: 55px;
  overflow: visible;
}
#btn_and_labal_all_grp {
  position: absolute;
  width: 100%;
  height: 1210.756px;
  left: 6.084px;
  top: 166.74px;
  overflow: visible;
}
#tab_design_emp_off {
  fill: rgba(233, 255, 230, 1);
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.5px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.tab_design_emp_off {
  overflow: visible;
  position: absolute;
  width: 379.49px;
  height: 1211.296px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Scroll_Group_emo_off {
  mix-blend-mode: normal;
  position: absolute;
  width: 375px;
  height: 540px;
  left: 0.916px;
  top: 67.26px;
  overflow-x: hidden;
  overflow-y: scroll;
}
#all_input_grp {
  position: absolute;
  width: 250px;
  height: 1079px;
  left: 63px;
  top: 0px;
  overflow: visible;
}
#unique_id_grp_emp_off {
  position: absolute;
  width: 250px;
  height: 68px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#unique_input_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.unique_input_emp_off {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 45px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#unque_id_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 76px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#designation_emp_off_grp {
  position: absolute;
  width: 250px;
  height: 68px;
  left: 0px;
  top: 161px;
  overflow: visible;
}
#designation_input_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.designation_input_emp_off {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 45px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#designation_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 91px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#report_emp_off_grp {
  position: absolute;
  width: 250px;
  height: 68px;
  left: 0px;
  top: 239px;
  overflow: visible;
}
#reporting_to_lvl_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 97px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#reporting_to_input_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.reporting_to_input_emp_off {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 45px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#access_type_emp_off {
  position: absolute;
  width: 250px;
  height: 71px;
  left: 0px;
  top: 317px;
  overflow: visible;
}
#access_type_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.access_type_input {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 22px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#DOB_profile_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 91px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#BAD_grp_emp_off {
  position: absolute;
  width: 250px;
  height: 115px;
  left: 0px;
  top: 728px;
  overflow: visible;
}
#BAD_input_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.BAD_input_emp_off {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 92px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#BAD_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 152px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#UPI_ID_grp_emp_off {
  position: absolute;
  width: 250px;
  height: 72px;
  left: 0px;
  top: 646px;
  overflow: visible;
}
#aadhar_bdr_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.aadhar_bdr_emp_off {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#UPI_id_lbl_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 48px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#DOJ_grp_emp_off {
  position: absolute;
  width: 250px;
  height: 73px;
  left: 0px;
  top: 78px;
  overflow: visible;
}
#DOJ_lbl_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 114px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#DOJ_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.DOJ_input {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 50px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#stop_ht_emp_off {
  position: absolute;
  width: 105px;
  height: 72px;
  left: 0px;
  top: 965px;
  overflow: visible;
}
#stop_hr_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 58px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#stop_ht_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.stop_ht_input {
  position: absolute;
  overflow: visible;
  width: 105px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#Passport_no_emp_off {
  position: absolute;
  width: 250px;
  height: 73px;
  left: 0px;
  top: 480px;
  overflow: visible;
}
#pass_number_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 131px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#passport_no_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.passport_no_emp_off {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 50px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#start_hr_emp_off_grp {
  position: absolute;
  width: 105px;
  height: 72px;
  left: 0px;
  top: 883px;
  overflow: visible;
}
#start_hr_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 58px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#start_hr_emp_off_bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.start_hr_emp_off_bdr {
  position: absolute;
  overflow: visible;
  width: 105px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#Start_min_emp_off {
  position: absolute;
  width: 105px;
  height: 72px;
  left: 145px;
  top: 883px;
  overflow: visible;
}
#start_min_input_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.start_min_input_emp_off {
  position: absolute;
  overflow: visible;
  width: 105px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#start_min_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Stop_min_emp_off {
  position: absolute;
  width: 105px;
  height: 73px;
  left: 145px;
  top: 965px;
  overflow: visible;
}
#stop_min_input_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.stop_min_input_emp_off {
  position: absolute;
  overflow: visible;
  width: 105px;
  height: 49px;
  left: 0px;
  top: 24px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#stop_min_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#PED_grp_emp_off {
  position: absolute;
  width: 250px;
  height: 73px;
  left: 0px;
  top: 562px;
  overflow: visible;
}
#PED_emp_off {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 154px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#dob_bdr_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.dob_bdr_emp_off {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 50px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#swtch_emp_off {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.swtch_emp_off {
  position: absolute;
  overflow: visible;
  width: 47px;
  height: 20px;
  left: 145px;
  top: 1058px;
}
#active_emp_off {
  left: 204px;
  top: 1057px;
  position: absolute;
  overflow: visible;
  width: 47px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#aadhar_lvl_emp_off {
  left: 80px;
  top: 853px;
  position: absolute;
  overflow: visible;
  width: 91px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#pan_number_grp {
  position: absolute;
  width: 250px;
  height: 72px;
  left: 0px;
  top: 398px;
  overflow: visible;
}
#pan_bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.pan_bdr {
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 6px;
}
#Pan_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 60px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#update_btn_rgrp {
  position: absolute;
  width: 132px;
  height: 50px;
  left: 50.916px;
  top: 626.26px;
  overflow: visible;
}
#register_btn_rgrp {
  position: absolute;
  width: 132px;
  height: 50px;
  left: 115.916px;
  top: 626.26px;
  overflow: visible;
}
#update_btn_emp_off {
  fill: rgba(162, 228, 197, 1);
}
.update_btn_emp_off {
  position: absolute;
  overflow: visible;
  width: 132px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Update_emp_off {
  left: 42px;
  top: 16px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#change_pass_btn_grp {
  position: absolute;
  width: 132px;
  height: 50px;
  left: 198.916px;
  top: 626.26px;
  overflow: visible;
}
#chang_pass_emp_off {
  fill: rgba(162, 228, 197, 1);
}
.chang_pass_emp_off {
  position: absolute;
  overflow: visible;
  width: 132px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Change_Password_emp_off {
  left: 8px;
  top: 16px;
  position: absolute;
  overflow: visible;
  width: 118px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#emp_off_tab_grp {
  position: absolute;
  width: 235px;
  height: 22px;
  left: 54px;
  top: 152px;
  overflow: visible;
}
#personal_tab_ {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 64px;
  white-space: nowrap;
  --web-animation: fadein undefineds undefined;
  --web-action-type: page;
  --web-action-target: add_new_employee_per.html;
  cursor: pointer;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#office_info_lbl_tab {
  left: 157px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}

/* person*/
