#Profile_grp_with_time {
  position: absolute;
  width: 142px;
  height: 183px;
  left: 20px;
  top: 62px;
  overflow: visible;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#profile_bdr_2_t {
  fill: url(#profile_bdr_2_t);
}
.profile_bdr_2_t {
  position: absolute;
  overflow: visible;
  width: 141px;
  height: 147px;
  left: 0px;
  top: 0px;
}
#profile_bdr_in {
  fill: rgba(255, 255, 255, 1);
}
.profile_bdr_in {
  position: absolute;
  overflow: visible;
  width: 118px;
  height: 25px;
  left: 12px;
  top: 116px;
}
#Ronnie_lb {
  left: 15px;
  top: 115px;
  position: absolute;
  overflow: visible;
  width: 110px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#time_bde_out_x {
  fill: url(#time_bde_out_x);
}
.time_bde_out_x {
  position: absolute;
  overflow: visible;
  width: 141px;
  height: 33px;
  left: 1px;
  top: 150px;
}
#time_bdr_in {
  fill: rgba(255, 255, 255, 1);
}
.time_bdr_in {
  position: absolute;
  overflow: visible;
  width: 100px;
  height: 18px;
  left: 21px;
  top: 158px;
}
#time_lbl {
  left: 43px;
  top: 157px;
  position: absolute;
  overflow: visible;
  width: 52px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(37, 37, 37, 1);
}
#profile_img {
  position: absolute;
  width: 77px;
  height: 79px;
  left: 32px;
  top: 11px;
  overflow: visible;
  cursor: pointer;
}
