.mediaViewInfo {
  --web-view-name: sign_up;
  --web-view-id: sign_up;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: sign_up;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#sign_up {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  overflow: scroll;
  --web-view-name: sign_up;
  --web-view-id: sign_up;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#n_0944467 {
  width: 90rem;
  height: 80rem;
  top: 0px;
  overflow: visible;
}
#Group_11 {
  position: absolute;
  width: 659px;
  height: 1281px;
  left: 339px;
  top: 43px;
  overflow: visible;
}
#Rectangle_1_j {
  fill: url(#Rectangle_1_j);
  height: 1267px;
}
.Rectangle_1_j {
  position: absolute;
  overflow: visible;
  width: 659px;
  height: 1247px;
  left: 0px;
  top: 0px;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #dbf2e7;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#Group_10 {
  position: absolute;
  width: 584px;
  height: 1167px;
  left: 38px;
  top: 32px;
  overflow: visible;
}
#profile {
  position: absolute;
  width: 110px;
  height: 110px;
  left: 223px;
  top: 0px;
  overflow: visible;
}
#Rectangle_2 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_2 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 158px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_3 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_3 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 301px;
  top: 158px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_4 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_4 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 232px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_5 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_5 {
  position: absolute;
  overflow: visible;
  width: 286px;
  height: 59px;
  left: 301px;
  top: 228px;
  font-size: 17px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_6 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_6 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 306px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_7 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_7 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 301px;
  top: 306px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_8 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_8 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 380px;
  font-size: 17px;
  /* border: 2px solid darkcyan; */
  border-radius: 10px;
}
#Rectangle_9 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_9 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 301px;
  top: 380px;
  font-size: 15px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_10 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_10 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 454px;
  font-size: 15px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_11 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_11 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 301px;
  top: 454px;
  font-size: 15px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_12 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_12 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 528px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_13 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_13 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 301px;
  top: 528px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_14 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_14 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 602px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_15 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_15 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 301px;
  top: 602px;
  font-size: 17px;
  border-radius: 10px;
}
#Rectangle_16 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_16 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 676px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_17 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_17 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 301px;
  top: 676px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_18 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_18 {
  position: absolute;
  overflow: visible;
  width: 584px;
  height: 116px;
  left: 0px;
  top: 750px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_19 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_19 {
  position: absolute;
  overflow: visible;
  width: 584px;
  height: 90px;
  left: 0px;
  top: 886px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_21 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_21 {
  position: absolute;
  overflow: visible;
  width: 141px;
  height: 54px;
  left: 0px;
  top: 1022px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_22 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_22 {
  position: absolute;
  overflow: visible;
  width: 142px;
  height: 54px;
  left: 144px;
  top: 1022px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_23 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_23 {
  position: absolute;
  overflow: visible;
  width: 142px;
  height: 54px;
  left: 298px;
  top: 1022px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_24 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_24 {
  position: absolute;
  overflow: visible;
  width: 141px;
  height: 54px;
  left: 443px;
  top: 1022px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_Password {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_Password {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  top: 1096px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_25 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
#Rectangle_cnfPassword {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_cnfPassword {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 301px;
  top: 1096px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
.Rectangle_25 {
  position: absolute;
  overflow: visible;
  width: 16px;
  height: 17px;
  left: 488px;
  top: 1085px;
}
#Active {
  left: 514px;
  top: 1082px;
  position: absolute;
  overflow: visible;
  width: 48px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: rgba(55, 54, 54, 1);
}
#Group_6 {
  position: absolute;
  width: 359px;
  height: 127px;
  left: 150px;
  top: 1156px;
  overflow: visible;
}
#Group_2:hover {
  left: 8px;
  top: 8px;
}
#Group_2 {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#Rectangle_26_bf {
  fill: rgba(162, 228, 197, 1);
}
.Rectangle_26_bf {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Update_bg {
  left: 54px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 56px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}

#Rectangle_27_bl {
  fill: rgba(162, 228, 197, 1);
}
.Rectangle_27_bl {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Change_Password_bm {
  left: 15px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 133px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Group_12:hover {
  left: 235px;
  top: 1175px;
}
#Group_12 {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 229px;
  top: 1265px;

  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#register_upiId {
  position: absolute;
  overflow: visible;
  width: 588px;
  height: 54px;
  left: 0px;
  top: 1171px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Rectangle_27_bx {
  fill: rgba(162, 228, 197, 1);
}
.Rectangle_27_bx {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Register_by {
  left: 51px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Group_9 {
  position: absolute;
  width: 601px;
  height: 50px;
  left: 31px;
  top: 18px;
  overflow: visible;
}
#notification-bell {
  position: absolute;
  width: 37px;
  height: 38px;
  left: 0px;
  top: 7px;
  overflow: visible;
}
#Group_7 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 496px;
  top: 0px;
  overflow: visible;
  border-radius: 50px;
}
#Ellipse_1 {
  fill: rgba(255, 255, 255, 1);
}
.Ellipse_1 {
  position: absolute;
  overflow: visible;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
}

#Group_8 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 551px;
  top: 0px;
  overflow: visible;
  border-radius: 50px;
}
#Ellipse_1_b {
  fill: rgba(255, 255, 255, 1);
}
.Ellipse_1_b {
  position: absolute;
  overflow: visible;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#logout1 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10px;
  top: 10px;
  overflow: visible;
}
#logo {
  position: absolute;
  width: 118px;
  height: 118px;
  left: 24px;
  top: 23px;
  overflow: visible;
}
