#mid_change_section {
  position: absolute;
  width: 586px;
  height: 237.348px;
  left: 59px;
  top: 25px;
  overflow: visible;
}
#app_res_grp_holiday {
  position: absolute;
  width: 383.862px;
  height: 80.348px;
  left: 202px;
  top: 157px;
  overflow: visible;
}
#Start_Session_lbl_holi {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#bdr_holi {
  fill: rgba(255, 255, 255, 1);
}
.bdr_holi {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 1px;
  top: 29.374px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_holi_1 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_holi_1 {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 5px;
  top: 33.374px;
}
#add_buttton_img_holi {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 10.216px;
  top: 38.798px;
  overflow: visible;
}
#link_up_holi {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 13.917px;
  top: 22px;
  overflow: visible;
}
#link_down_holi {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 14.126px;
  top: 63.6px;
  overflow: visible;
}
#left_link_up_holi_b {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up_holi_b {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 22.641px;
  top: 33.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down_holi_b {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down_holi_b {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 17.503px;
  top: 67.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#bdr_back_holi {
  fill: rgba(255, 255, 255, 1);
}
.bdr_back_holi {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 340.862px;
  top: 29.374px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_holi_ca {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_holi_ca {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 344.862px;
  top: 33.374px;
}
#link1_mid_top_holi {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 353.779px;
  top: 22px;
  overflow: visible;
}
#link1_mid_down_holi {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 353.988px;
  top: 63.6px;
  overflow: visible;
}
#left_link_up_holi_cd {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up_holi_cd {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 362.502px;
  top: 33.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down_holi_ce {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down_holi_ce {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 357.364px;
  top: 67.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#occasion_input {
  fill: rgba(255, 255, 255, 1);
}
.occasion_input {
  position: absolute;
  overflow: visible;
  width: 586px;
  height: 87px;
  left: 0px;
  top: 55px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#date_btn {
  fill: rgba(255, 255, 255, 1);
}
.date_btn {
  position: absolute;
  overflow: visible;
  width: 202px;
  height: 40px;
  left: 0px;
  top: 0px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
