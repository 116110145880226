.mediaViewInfo {
  --web-view-name: Employee portal OP;
  --web-view-id: Employee_portal_OP;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: Employee_portal_OP;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#Employee_portal_OP {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: Employee portal OP;
  --web-view-id: Employee_portal_OP;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-enable-deep-linking: true;
}
#Path_152_h {
  fill: url(#Path_152_h);
}
.Path_152_h {
  overflow: visible;
  position: absolute;
  height: 100vh;
  right: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_153 {
  fill: rgba(255, 255, 255, 1);
}
.Path_153 {
  overflow: visible;
  position: absolute;
  width: 97.645px;
  height: 78.097px;
  right: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_154 {
  fill: rgba(255, 255, 255, 1);
}
.Path_154 {
  overflow: visible;
  position: absolute;
  width: 119.344px;
  height: 102.026px;
  left: 1219.864px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Group_11 {
  position: absolute;
  width: 355px;
  height: 413px;
  left: 161px;
  top: 138px;
  overflow: visible;
}
#Rectangle_1 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 0.5px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_1 {
  position: absolute;
  overflow: visible;
  width: 355px;
  height: 413px;
  left: 0px;
  top: 0px;
}
#Rectangle_2 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_2 {
  position: absolute;
  overflow: visible;
  width: 261px;
  height: 50px;
  left: 47px;
  top: 107px;
}
#Rectangle_3 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_3 {
  position: absolute;
  overflow: visible;
  width: 261px;
  height: 50px;
  left: 47px;
  top: 187px;
}
#Rectangle_4 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_4 {
  position: absolute;
  overflow: visible;
  width: 127px;
  height: 50px;
  left: 114px;
  top: 326px;
}
#logo_login {
  position: absolute;
  width: 118px;
  height: 118px;
  left: 116px;
  top: 0px;
  overflow: visible;
}
#My_password-bro {
  position: absolute;
  width: 466px;
  height: 466px;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
}
.login-box {
  position: absolute;
  top: 50%;
  right: 45%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 420px;
  padding: 80px 40px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  /* background: radial-gradient(circle 993px at .5% 50.5%,rgba(137,171,245,.37) 0,#f5f7fc 100.2%);; */
}

h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #333;
  text-align: center;
}

.login-box form .user-box {
  position: relative;
  top: 30px;
}

.login-box form .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #333;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #333;
  outline: none;
  background: transparent;
}

.login-box form .user-box label {
  position: absolute;
  top: 50;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #333;
  pointer-events: none;
  transition: 0.5s;
}

.login-box form .user-box input:focus ~ label,
.login-box form .user-box input:valid ~ label {
  top: 40px;
  left: 0;
  color: #5acf6d;
  font-size: 14px;
}

.login-box link {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  width: 100px;
  left: 79px;
}

.login-box link:hover {
  background: #6fff6a;
  box-shadow: 0 0 10px #6fff6a, 0 0 40px #6fff6a, 0 0 80px #6fff6a;
}

.login-box link span {
  position: absolute;
  display: block;
}

.login-box link span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #6fff6a);
  animation: animate1 1s linear infinite;
}

@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box link span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #6fff6a);
  animation: animate2 1s linear infinite;
  animation-delay: 0.7s;
}

@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box link span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #6fff6a);
  animation: animate3 1s linear infinite;
  animation-delay: 1s;
}

@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box link span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear;
}
#logbtn {
  /* color:env(SECONDARY_COLOR); */
  top:30px;
  position: relative;
  width: 100px;
  border-radius: 50px;;
  height: 34px;
  font-style: italic;
  font-size: 20px;
  border-color: black;
  float: right;
}
#logbtn:hover {
  background-color: #6fff6a;
  color: white;
}
.Forgot_Password {
  position: relative;
  top: 80px;
  left: 53%;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  --web-action-type: page;
  --web-action-target: sign_up_mob.html;
}
