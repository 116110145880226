.mediaViewInfo {
  --web-view-name: attendance page;
  --web-view-id: attendance_page;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: attendance_page;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#attendance_page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  overflow: visible;
  --web-view-name: attendance page;
  --web-view-id: attendance_page;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#overall_bg_design {
  position: fixed;
  width: 1360px;
  height: 768px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Rectangle_28 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_28 {
    position: absolute;
    overflow: visible;
    width: calc(100vw - 220px);
    height: calc(100vh - 320px);
    left: 193px;
    top: 320px;
    border: 1px solid black;
}
#Profile_grp_with_time {
  position: absolute;
  width: 142px;
  height: 183px;
  left: 20px;
  top: 62px;
  overflow: visible;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#profile_bdr_2_l {
  fill: url(#profile_bdr_2_l);
}
.profile_bdr_2_l {
  position: absolute;
  overflow: visible;
  width: 141px;
  height: 147px;
  left: 0px;
  top: 0px;
}
#profile_bdr_in {
  fill: rgba(255, 255, 255, 1);
}
.profile_bdr_in {
  position: absolute;
  overflow: visible;
  width: 118px;
  height: 25px;
  left: 12px;
  top: 116px;
}
#Ronnie_lb {
  left: 45px;
  top: 117px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(37, 37, 37, 1);
}
#time_bde_out_p {
  fill: url(#time_bde_out_p);
}
.time_bde_out_p {
  position: absolute;
  overflow: visible;
  width: 141px;
  height: 33px;
  left: 1px;
  top: 150px;
}
#time_bdr_in {
  fill: rgba(255, 255, 255, 1);
}
.time_bdr_in {
  position: absolute;
  overflow: visible;
  width: 100px;
  height: 18px;
  left: 21px;
  top: 158px;
}
#time_lbl {
  left: 43px;
  top: 157px;
  position: absolute;
  overflow: visible;
  width: 52px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(37, 37, 37, 1);
}
#profile_img {
  position: absolute;
  width: 77px;
  height: 79px;
  left: 32px;
  top: 11px;
  overflow: visible;
}
#menu_grp {
  position: absolute;
  width: 138px;
  height: 351px;
  left: 20px;
  top: 255px;
  overflow: visible;
}
#full_bg_menu {
  fill: rgba(255, 255, 255, 1);
}
.full_bg_menu {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: calc(100vh - 260px);
    left: 0px;
    top: 0px;
    box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
    border-radius: 15px;
}
#Attendance_lbl {
  left: 41px;
  top: 22px;
  position: absolute;
  overflow: visible;
  width: 92px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#immigration_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 19px;
  overflow: visible;
}
#Holidays_lbl {
  left: 45px;
  top: 297px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#holiday_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 294px;
  overflow: visible;
}
#Leaves_lbl {
  left: 45px;
  top: 78px;
  position: absolute;
  overflow: visible;
  width: 54px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#sick_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 75px;
  overflow: visible;
}
#Messages_lbl {
  left: 43px;
  top: 189px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#msg_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 186px;
  overflow: visible;
}
#Approval_lbl {
  left: 43px;
  top: 245px;
  position: absolute;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#sick_img_ {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 241px;
  overflow: visible;
}
#Tasks_lbl {
  left: 45px;
  top: 134px;
  position: absolute;
  overflow: visible;
  width: 42px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#task_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 130px;
  overflow: visible;
}
#Group_22 {
  position: absolute;
  width: 1254px;
  height: 40px;
  left: 0px;
  top: 25px;
  overflow: visible;
}
#home_btn {
  fill: rgba(255, 255, 255, 1);
}
.home_btn {
  position: absolute;
  overflow: visible;
  width: 40px;
  height: 40px;
  left: 1159px;
  top: 0px;
}
#addemployee_img {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  /* left: 1065px; */
  /* top: 0px; */
  /* overflow: visible; */
}
#logout_btn {
  fill: rgba(255, 255, 255, 1);
}
.logout_btn {
  position: absolute;
  overflow: visible;
  width: 40px;
  height: 40px;
  left: 1214px;
  top: 0px;
}
#logout_img {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  /* left: 1219px; */
  /* top: 5px; */
  overflow: visible;
}
#home1 {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  /* left: 1219px; */
  /* top: 5px; */
  overflow: visible;
}
#notification_bell_img {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  overflow: visible;
  /* top: 0px;
  left: -40px; */
}
#msg_menu {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  overflow: visible;
  /* top: 0px;
  left: 1109px; */
}
#left_link_up {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 476.641px;
  top: 277.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 471.503px;
  top: 311.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_up_bf {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up_bf {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 816.502px;
  top: 277.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down_bg {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down_bg {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 811.364px;
  top: 311.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Group_27 {
  position: absolute;
  width: 885px;
  height: 262.348px;
  left: 193px;
  top: 62px;
  overflow: visible;
}
#mid_bg_ {
  position: absolute;
  width: 885px;
  height: 215px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#comment_bg_box_bk {
  fill: url(#comment_bg_box_bk);
  /* width: calc(93vw - 22vw); */
}
.comment_bg_box_bk {
  position: absolute;
  overflow: visible;
  width: calc(40vw - 135px);
  height: 215px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#mid_changes_ {
    position: absolute;
    width: 641px;
    height: 212.348px;
    left: calc(50% - 320px);
    top: 50px;
    overflow: visible;
}
#timer_login {
  left: -30px;
  top: 60px;
  position: absolute;
  overflow: visible;
  width: 150px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(37, 37, 37, 1);
}
#total_logintime {
  /* left: -30px; */
  top: 102px;
  position: relative;
  overflow: visible;
  /* width: 150px; */
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(37, 37, 37, 1);
}
#shift_start_time {
  left: -30px;
  top: -38px;
  position: absolute;
  overflow: visible;
  width: 150px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(37, 37, 37, 1);
}
#shift_end_time {
  left: 508px;
  top: -38px;
  position: absolute;
  overflow: visible;
  width: 150px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(37, 37, 37, 1);
}
#img_timer {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 31px;
  top: 28px;
  overflow: visible;
}
#timer_login_stop {
  left: 520px;
  top: 60px;
  position: absolute;
  overflow: visible;
  width: 150px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(37, 37, 37, 1);
}
#img_timer_stop {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 580px;
  top: 28px;
  overflow: visible;
}
#comment_box {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.comment_box {
  position: absolute;
  overflow: visible;
  width: 371px;
  height: 99px;
  left: 135px;
  top: 0px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#bdr_2 {
  fill: rgba(255, 255, 255, 1);
}
.bdr_2 {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 140px;
  top: 161.374px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_1 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_1 {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 144px;
  top: 165.374px;
}
#play-buttton_img {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 154px;
  top: 172.874px;
  overflow: visible;
}
#link_up {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 152.917px;
  top: 154px;
  overflow: visible;
}
#link_down {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 153.126px;
  top: 195.6px;
  overflow: visible;
}
#mid_grp_ {
  position: absolute;
  width: 43px;
  height: 43px;
  left: 479.862px;
  top: 161.374px;
  overflow: visible;
}
#bdr_back {
  fill: rgba(255, 255, 255, 1);
}
.bdr_back {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 4px;
  top: 4px;
}
#stop-button_img {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 12px;
  top: 11.5px;
  overflow: visible;
}
#link1_mid_top {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 492.779px;
  top: 154px;
  overflow: visible;
}
#link1_mid_down {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 492.988px;
  top: 195.6px;
  overflow: visible;
}
#Start_Session_lbl {
  left: 119px;
  top: 132px;
  position: absolute;
  overflow: visible;
  width: 96px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Session_End_lbl {
  left: 458px;
  top: 133px;
  position: absolute;
  overflow: visible;
  width: 90px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#filter_full_grp {
  position: absolute;
  width: 218px;
  height: 262.974px;
  right: 30px;
  top: 62px;
  overflow: visible;
}
#unchangable_grp {
  position: absolute;
  width: 218px;
  height: 262.974px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#full_bg_design_b {
  fill: url(#full_bg_design_b);
}
.full_bg_design_b {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 214px;
  left: 0px;
  top: 0px;
}
#Search_lbl_ {
  left: 83px;
  top: 185px;
  position: absolute;
  overflow: visible;
  width: 45px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#right_chain_grp {
  position: absolute;
  width: 43px;
  height: 58.348px;
  left: 85px;
  top: 204.626px;
  overflow: visible;
}
#Group_17 {
  position: absolute;
  width: 43px;
  height: 43px;
  left: 0px;
  top: 7.374px;
  overflow: visible;
}
#bdr_2_cb {
  fill: rgba(255, 255, 255, 1);
}
.bdr_2_cb {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_1_cc {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_1_cc {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 4px;
  top: 4px;
}
#search_img_ {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 11px;
  top: 11.5px;
  overflow: visible;
}
#link1_up {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 12.917px;
  top: 0px;
  overflow: visible;
}
#link1_down {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 13.126px;
  top: 41.6px;
  overflow: visible;
}
#left_link_up_cg {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up_cg {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 21.641px;
  top: 11.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down_ch {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down_ch {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 16.503px;
  top: 45.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#filter_option_grp {
  position: absolute;
  width: 185px;
  height: 187px;
  left: 16px;
  top: 0px;
  overflow: visible;
}
#employee_bdr_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.employee_bdr_input {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 43px;
  left: 0px;
  top: 22px;
  font-size: 15px;
  padding: 0px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Employee_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 74px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#from_bdr_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.from_bdr_input {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 44px;
  left: 0px;
  top: 82px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#From_lbl {
  left: 0px;
  top: 62px;
  position: absolute;
  overflow: visible;
  width: 40px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#to_bdr_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.to_bdr_input {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 44px;
  left: 0px;
  top: 143px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#To_lbl {
  left: 0px;
  top: 124px;
  position: absolute;
  overflow: visible;
  width: 18px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#emp_tast_grp {
  position: absolute;
  width: 185px;
  height: 128px;
  left: 17px;
  top: 43px;
  overflow: visible;
}
#employee_bdr_input_task {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.employee_bdr_input_task {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 34px;
  left: 0px;
  top: 22px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Employee_lbl_task {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 74px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#to_bdr_input_task {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.to_bdr_input_task {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 34px;
  left: 0px;
  top: 94px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#To_lbl_task {
  left: 0px;
  top: 72px;
  position: absolute;
  overflow: visible;
  width: 82px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
