.mediaViewInfo {
  --web-view-name: attendance mob – 1;
  --web-view-id: attendance_mob__1;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-enable-scale-up: true;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: attendance_mob__1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#attendance_mob__1 {
  position: absolute;
  width: 375px;
  height: 812px;
  background-color: rgba(255, 255, 255, 1);
  overflow: visible;
  --web-view-name: attendance mob – 1;
  --web-view-id: attendance_mob__1;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-enable-scale-up: true;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#full_bg_mob_1 {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#card_grp {
  position: absolute;
  width: 302px;
  min-height: 214px;
  left: 37px;
  top: 553px;
  overflow: visible;
}
#card_grid_grp {
  position: absolute;
  width: 299px;
  height: 541px;
  left: 37px;
  top: 225px;
  overflow: visible;
}
#below_card_mob_1_w {
  fill: url(#below_card_mob_1_w);
}
.below_card_mob_1_w {
  position: absolute;
  overflow: visible;
  width: 302px;
  min-height: 214px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#card_1_grp {
  position: relative;
  width: 282px;
  height: 97px;
  left: 10px;
  top: 7px;
  overflow: visible;
  margin-bottom: 10px;
}
#card_bg_mob_1 {
  fill: rgba(255, 255, 255, 1);
}
.card_bg_mob_1 {
  position: absolute;
  overflow: visible;
  width: 282px;
  height: 97px;
  left: 0px;
  top: 0px;
}
#line_bar_card_1_mob {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.line_bar_card_1_mob {
  overflow: visible;
  position: absolute;
  width: 266px;
  height: 1px;
  left: 8.5px;
  top: 18.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#affan_mob_1 {
  left: 9px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 121px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#date_mob_1 {
  left: 196px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 78px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#time_mob_1 {
  left: 28px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 63px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#clock_mob_1 {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 27px;
  overflow: visible;
}
#des_img_mob_1 {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 48px;
  overflow: visible;
}
#comments_img_mob_1 {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 71px;
  overflow: visible;
}
#IN_mob_card_1 {
  left: 106px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Out_mob_card_1 {
  left: 253px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 24px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#At_Work_lbl_img_mob_1 {
  left: 180px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 48px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#clock_img_mob_1 {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 161px;
  top: 27px;
  overflow: visible;
}
#n_des_img_mob_1 {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 161px;
  top: 47px;
  overflow: visible;
}
#Late_due_to_traffic_jam_lnl {
  left: 29px;
  top: 69px;
  position: absolute;
  overflow: visible;
  width: 97px;
  height: 17px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#comments_img_mob_2 {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 161px;
  top: 71px;
  overflow: visible;
}
#out_comments {
  left: 181px;
  top: 69px;
  position: absolute;
  overflow: visible;
  width: 97px;
  height: 17px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Office_out_card_1 {
  left: 180px;
  top: 48px;
  position: absolute;
  overflow: visible;
  width: 94px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#n_km_from_Office_in_card1 {
  left: 27px;
  top: 48px;
  position: absolute;
  overflow: visible;
  width: 94px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#card_2_grp {
  position: absolute;
  width: 282px;
  height: 97px;
  left: 10px;
  top: 114px;
  overflow: visible;
}
#line_bar_card_2_mob {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.line_bar_card_2_mob {
  overflow: visible;
  position: absolute;
  width: 266px;
  height: 1px;
  left: 8.5px;
  top: 19.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#bg_card_mob {
  fill: rgba(255, 255, 255, 1);
}
.bg_card_mob {
  position: absolute;
  overflow: visible;
  width: 282px;
  height: 97px;
  left: 0px;
  top: 0px;
}
#affan_lbl_mob_card {
  left: 9px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 121px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#date_lbl_mob {
  left: 196px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 78px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#time_in_lvl {
  left: 28px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 63px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#clock_in_img_ {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 27px;
  overflow: visible;
}
#in_des_img_mob {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 48px;
  overflow: visible;
}
#comments_in_img_mob {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 71px;
  overflow: visible;
}
#IN_lbl_mob {
  left: 106px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Out_lbl_mob {
  left: 253px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 24px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#time_lbl_mob {
  left: 180px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 63px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Going_for_lunch_lbl_mob {
  left: 182px;
  top: 69px;
  position: absolute;
  overflow: visible;
  width: 90px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#clock_img_mob {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 161px;
  top: 27px;
  overflow: visible;
}
#out_des_img_mob {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 161px;
  top: 47px;
  overflow: visible;
}
#comments_img_mob {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 161px;
  top: 71px;
  overflow: visible;
}
#Returned_from_break_in {
  left: 29px;
  top: 69px;
  position: absolute;
  overflow: visible;
  width: 120px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#n_km_from_Office_out {
  left: 180px;
  top: 48px;
  position: absolute;
  overflow: visible;
  width: 94px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#n_km_from_Office_in {
  left: 27px;
  top: 48px;
  position: absolute;
  overflow: visible;
  width: 94px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#top_info_bar_mob_grp {
  position: absolute;
  width: 300px;
  height: 155px;
  left: 36px;
  top: 63px;
  overflow: visible;
}
#bg_mob_bz {
  fill: url(#bg_mob_bz);
}
.bg_mob_bz {
  position: absolute;
  overflow: visible;
  width: 300px;
  height: 155px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#Affan_lbl_mob {
  left: 123px;
  top: 35px;
  position: absolute;
  overflow: visible;
  width: 121px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#profile_mob {
  position: absolute;
  width: 85px;
  height: 85px;
  left: 21px;
  top: 20px;
  overflow: visible;
}
#time_lbl_mob_b {
  left: 27px;
  top: 105px;
  position: absolute;
  overflow: visible;
  width: 74px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
}
#Start_lbl_mob {
  left: 149px;
  top: 101px;
  position: absolute;
  overflow: visible;
  width: 31px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#startstop_grp {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 146px;
  top: 64px;
  overflow: visible;
}
#bdr_ps_btn_mob {
  fill: rgba(255, 255, 255, 1);
}
.bdr_ps_btn_mob {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#play-buttton_img_mob {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 6px;
  top: 6px;
  overflow: visible;
}
#filter_btn_grp_mob {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 205px;
  top: 64px;
  overflow: visible;
}
#bdr_filter_mob {
  fill: rgba(255, 255, 255, 1);
}
.bdr_filter_mob {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#filter_img_mob {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 8px;
  top: 8px;
  overflow: visible;
}
#Filter_lbl_mob {
  left: 206px;
  top: 101px;
  position: absolute;
  overflow: visible;
  width: 34px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#top_bar_grp {
  position: absolute;
  width: 340px;
  height: 41px;
  left: 16px;
  top: 4px;
  overflow: visible;
}
#noti_grp_mob {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 203px;
  top: 0px;
  overflow: visible;
}
#bdr_noti_mob {
  fill: rgba(255, 255, 255, 1);
}
.bdr_noti_mob {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 58px;
  height: 58px;
  left: 0px;
  top: 0px;
}
#notification-bell_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 7px;
  top: 7px;
  overflow: visible;
}
#add_member_grp_mob {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 106px;
  top: 1px;
  overflow: visible;
}
#bdr_btn_mob {
  fill: rgba(255, 255, 255, 1);
}
.bdr_btn_mob {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 58px;
  height: 58px;
  left: 0px;
  top: 0px;
}
#add-user_btn_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 7px;
  top: 7px;
  overflow: visible;
}
#msg_member_grp_mob {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 153px;
  top: 1px;
  overflow: visible;
}
#msg-user_btn_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 7px;
  top: 7px;
  overflow: visible;
}
#home_btn_grp_mob {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#bdr_btn_mob_cj {
  fill: rgba(255, 255, 255, 1);
}
.bdr_btn_mob_cj {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 58px;
  height: 58px;
  left: 0px;
  top: 0px;
}
#home_btn_img_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 8px;
  top: 7px;
  overflow: visible;
}
#filter_btn_grp_mob_cl {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 300px;
  top: 0px;
  overflow: visible;
}
#bdr_menu_btn_mob {
  fill: rgba(255, 255, 255, 1);
}
.bdr_menu_btn_mob {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 58px;
  height: 58px;
  left: 0px;
  top: 0px;
}
#menu_btn_img_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 8px;
  top: 8px;
  overflow: visible;
}
#Line_1_underline_att {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_1_underline_att {
  overflow: visible;
  position: absolute;
  width: 266px;
  height: 1px;
  left: 54.5px;
  top: 364.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#mid_overall {
  position: absolute;
  width: 302px;
  height: 323px;
  left: 37px;
  top: 225px;
  overflow: visible;
}
#btn_bg_grp_mob {
  position: absolute;
  width: 302px;
  height: 323px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#mig_bg_mob {
  position: absolute;
  width: 302px;
  height: 319px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Rectangle_9_ct {
  fill: url(#Rectangle_9_ct);
}
.Rectangle_9_ct {
  position: absolute;
  overflow: visible;
  width: 302px;
  height: 319px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#btn_full_grp {
  position: absolute;
  width: 129px;
  height: 55px;
  left: 87px;
  top: 268px;
  overflow: visible;
}
#Group_8 {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 4px;
  top: 20px;
  overflow: visible;
}
#Ellipse_2 {
  fill: rgba(255, 255, 255, 1);
}
.Ellipse_2 {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#play-buttton_img_task_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 5px;
  top: 5.5px;
  overflow: visible;
}
#reset_btn_leave {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 93px;
  top: 20px;
  overflow: visible;
}
#bdr_reset_leave {
  fill: rgba(255, 255, 255, 1);
}
.bdr_reset_leave {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#stop-button_img_leave {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 5px;
  top: 6px;
  overflow: visible;
}
#Create_lbl_ {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 44px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#Reset_lbl {
  left: 93px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 37px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#input_comment_mob {
  position: absolute;
  width: 235px;
  height: 103px;
  left: 33px;
  top: 56px;
  overflow: visible;
}
#mob_To_lbl {
  left: 67px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 102px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#mob_employee_bdr_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mob_employee_bdr_input {
  position: absolute;
  overflow: visible;
  width: 241px;
  height: 184px;
  left: 0px;
  top: -16px;
  font-size: 15px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}

/* filter */
#filter_grp_mob_overall {
  position: absolute;
  width: 299px;
  height: 541px;
  left: 36px;
  top: 224px;
  overflow: visible;
}
#full_bg_design_mob_bo {
  fill: url(#full_bg_design_mob_bo);
}
.full_bg_design_mob_bo {
  position: absolute;
  overflow: visible;
  width: 299px;
  height: 541px;
  left: 0px;
  top: 0px;
}
#filter_input_grp_mob {
  position: absolute;
  width: 236px;
  height: 165px;
  left: 31px;
  top: 10px;
  overflow: visible;
}
#employee_bdr_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.employee_bdr_input_mob {
  position: absolute;
  overflow: visible;
  width: 236px;
  height: 35px;
  left: 0px;
  top: 20px;
  font-size: 15px;
  /* padding: 15px; */
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Employee_lbl_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 66px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#from_bdr_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.from_bdr_input_mob {
  position: absolute;
  overflow: visible;
  width: 236px;
  height: 35px;
  left: 0px;
  top: 75px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#From_lbl_mob {
  left: 0px;
  top: 55px;
  position: absolute;
  overflow: visible;
  width: 35px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#to_bdr_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.to_bdr_input_mob {
  position: absolute;
  overflow: visible;
  width: 236px;
  height: 35px;
  left: 0px;
  top: 130px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#To_lbl_mob {
  left: 0px;
  top: 110px;
  position: absolute;
  overflow: visible;
  width: 16px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#filter_grp_search {
  position: absolute;
  width: 45px;
  height: 45px;
  left: 126px;
  top: 214px;
  overflow: visible;
}
#search_btn_mob {
  fill: rgba(255, 255, 255, 1);
}
.search_btn_mob {
  filter: drop-shadow(7px 5px 5px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 60px;
  height: 60px;
  left: 0px;
  top: 0px;
}
#search1_btn_img_mob {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 8px;
  top: 8px;
  overflow: visible;
}
#card_grid_grp_new {
  position: absolute;
  width: 299px;
  height: 541px;
  left: 37px;
  top: 225px;
  overflow: visible;
}
#below_card_mob_1_w_new {
  fill: url(#below_card_mob_1_w_new);
}
.below_card_mob_1_w_new {
  position: absolute;
  overflow: visible;
  width: 299px;
  height: 541px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#top_nav_logout {
  position: absolute;
  width: 40px;
  height: 41px;
  left: 250px;
  top: 0px;
  overflow: visible;
}
