* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 450px;
  height: 100%;
  text-align: center;
}
.img {
  text-align: center;
  border-radius: 50px;
}
.rowseProfile {
  background-image: radial-gradient(
    circle 993px at 0.5% 50.5%,
    rgba(137, 171, 245, 0.37) 0%,
    rgba(245, 247, 252, 1) 100.2%
  );
  width: 100%;
  min-width: 350px;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 15px;
}
.rowse {
  background-image: radial-gradient(
    circle 993px at 0.5% 50.5%,
    rgba(137, 171, 245, 0.37) 0%,
    rgba(245, 247, 252, 1) 100.2%
  );
  width: 100%;
  min-width: 350px;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 15px;
  transform: translateY(30%);
}

.btn1 {
  border: none;
  outline: none;
  height: 50px;
  width: 100px;
  border-radius: 4px;
  font-weight: bold;
  background-color: #14c871;
  color: white;
}
.links {
  font-size: 16px;
}
.form-group {
  padding: 1em;
}
.form-group h1 {
  text-align: center;
  color: #14c871;
}
.form-group h2 {
  text-align: center;
  font-size: 30px;
  color: #2c82f1;
}
.card {
  background-image: radial-gradient(
    circle 993px at 0.5% 50.5%,
    rgba(137, 171, 245, 0.37) 0%,
    rgba(245, 247, 252, 1) 100.2%
  );

  width: 30em;
  margin: 5% auto;

  border: 1px;
}
.cards {
  background-image: radial-gradient(
    circle 993px at 0.5% 50.5%,
    rgba(137, 171, 245, 0.37) 0%,
    rgba(245, 247, 252, 1) 100.2%
  );
  width: 20em;
  margin: 5% auto;
  background-color: white;
  border-radius: 8px;
}

.button-5 {
  display: flex;
  justify-content: space-evenly;
  padding: 5%;
}

.btnn {
  background-color: #14c871;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  line-height: 38px;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}
.btn2 {
  background-color: #14c871;
  border-radius: 6px;
  width: 100px;
  height: 50px;
  margin-left: 250px;
}
.btnn .btn {
  width: 100%;
  color: white;
  background-color: #14c871;
}
.bt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.bt .btn {
  background-color: #14c871;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  line-height: 48px;
  width: 100px;
  margin: 2em;
  color: white;
}
.col-12 {
  margin-right: 50%;
}
.nav-item {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.navbar .nav-item .nav-link {
  color: #5f687b;
}
.navbar .nav-item.active .nav-link,
.navbar .nav-item:hover .nav-link {
  color: #14c871;
}

.endtime {
  margin-right: 50px;
}
.times {
  text-align: center;
}

.container-1 {
  margin: 1em;
  min-width: 20em;
  max-width: 40em;
}

.task-meta {
  color: grey;
  margin-top: -10px;
  margin-bottom: 0.5em;
}

.task-meta img {
  margin-right: 5px;
}

form {
  text-align: left;
  margin: 10px 0px 10px 0px;
}

input {
  margin-bottom: 20px;
}

.card-1 {
  width: 40em;
  text-align: center;
  margin: auto;
  border: 1px;
}
.container-1 {
  margin-left: 40%;
  text-align: center;
}
.buttons {
  border-radius: 5px;
  width: 100px;
  align-items: center;
  justify-content: center;
}
.button-2 {
  border-radius: 5px;
  width: 100px;
}
.text-rights {
  border-radius: 10px;
  text-align: center;
}
.h1 {
  text-align: center;
  margin-top: 50px;
  color: #14c871;
}

.attendancePage {
  max-width: 50rem;
  max-height: max-content;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: white;
  padding: 10px;
  margin-top: 5%;
  margin-left: 10%;
  line-height: 3em;
}
.attendancePage h1 {
  margin-left: 20%;
  color: #14c871;
}
.tables {
  margin-top: 5%;
  margin-left: 40%;
}
.btnns {
  margin-top: 5%;
  align-items: center;
  justify-content: center;
  margin-left: 150px;
}
.btns {
  background-color: #2c82f1;
  border: none;
  border-radius: 6px;
  color: white;
  width: 15%;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
}

.heading {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 56px;
  color: white;
  font-family: "Raleway", sans-serif;
}

.namecard {
  height: "100%";
  background-color: #14c871;
  border: 2px solid #14c871;
  font-weight: 500;
  text-align: "center";
  vertical-align: "middle";
}

.time {
  font-weight: 700;
  font-size: 23px;
  font-family: "Raleway", sans-serif;
}

.details {
  border-radius: 25px 25px 0 0;
}
/* grid tutorial*/
/* grid-template-columns: :repeat(3,100px);
  grid-auto-rows:150px;
  grid-auto-rows:minmax(150px,auto);
  grid-template-rows:200px 200px;
  row-gap: 20px;
  column-gap: 10px;
   */
.grid-container {
  /* margin: 20px; */
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-auto-rows: minmax(70px, auto);
  column-gap: 100px;
  justify-content: center;
  /* padding: 20px; */
}
.salary-container {
  /* margin: 20px; */
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: minmax(70px, auto);
  column-gap: 30px;
  justify-content: center;
  /* padding: 20px; */
}
.main-container {
  background-image: radial-gradient(
    circle 993px at 50.5% 50.5%,
    rgba(137, 171, 245, 0.37) 0%,
    rgba(245, 247, 252, 1) 100.2%
  );
  border-radius: 20px;
  margin: 10px;
  height: 95%;
}

.payroll {
  background-image: radial-gradient(
    circle 993px at 0.5% 50.5%,
    rgba(137, 171, 245, 0.37) 0%,
    rgba(245, 247, 252, 1) 100.2%
  );
  width: 100%;
  min-width: 350px;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 15px;
  padding-top: 30px;
}

.card-payroll {
  width: 70em;
  text-align: center;
  margin: auto;
  border: 1px;
}
