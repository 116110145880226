.mediaViewInfo {
  --web-view-name: attendance page;
  --web-view-id: attendance_page;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: attendance_page;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#attendance_page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  overflow: visible;
  --web-view-name: attendance page;
  --web-view-id: attendance_page;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#overall_bg_design {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  overflow: visible;
}

#comment_bg_box_k {
  fill: url(#comment_bg_box_k);
}
.comment_bg_box_k {
  position: absolute;
  overflow: visible;
  width: 885px;
  height: 215px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#filter_grp {
  position: absolute;
  width: 218px;
  height: 214px;
  right: 20px;
  top: 62px;
  overflow: visible;
}
#full_bg_design_bo {
  fill: url(#full_bg_design_bo);
}
.full_bg_design_bo {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 214px;
  left: 0px;
  top: 0px;
}

#Group_22 {
  position: absolute;
  width: calc(100vw - 95px);
  height: 40px;
  left: 70px;
  top: 10px;
  overflow: visible;
}
#Group_27 {
    position: absolute;
    width: calc(100vw - 430px);
    height: 262.348px;
    left: 193px;
    top: 62px;
    overflow: visible;
}
