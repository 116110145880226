.mediaViewInfo {
  --web-view-name: sign_up;
  --web-view-id: sign_up;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: sign_up;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#profile_sign_up {
  position: absolute;
  width: 100vw;
  height: 1450px;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: sign_up;
  --web-view-id: sign_up;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#profile_n_0944467 {
  position: fixed;
  width: 80%;
  /* height: 1381px; */
  /* top: 0px; */
  left: 10%;
  overflow: visible;
}
#profile_full_bg_grp {
  position: relative;
  width: 660px;
  height: 1353px;
  left: calc(50% - 330px);
  top: 43px;
  overflow: visible;
}
#profile_Rectangle_1_j {
  fill: url(#profile_Rectangle_1_j);
  height: 1350px;
  display: block;
}
.profile_Rectangle_1_j_profile {
  position: relative;
  overflow: visible;
  width: 660px;
  height: 1313px;
  left: calc(50% - 330px);
  top: 0px;
}
#profile_profile {
  position: absolute;
  width: 110px;
  height: 110px;
  left: 261px;
  top: 32px;
  overflow: visible;
}
#profile_Rectangle_25 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_25 {
  position: absolute;
  overflow: visible;
  width: 16px;
  height: 17px;
  left: 275px;
  top: 165px;
}
#profile_Active {
  left: 300px;
  top: 160px;
  position: absolute;
  overflow: visible;
  width: 48px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: rgba(55, 54, 54, 1);
}
#profile_Group_6 {
  position: absolute;
  width: 359px;
  height: 64px;
  left: 150px;
  top: 1275px;
  overflow: visible;
}
#profile_pass_grp {
 
  position: absolute;
  width: 284px;
  height: 72px;
  left: 30px;
  top: 1260px;
  overflow: visible;
}
#profile_conf_pass_grp {
 
  position: absolute;
  width: 284px;
  height: 72px;
  left: 330px;
  top: 1260px;
  overflow: visible;
}
#profile_Group_2:hover {
  left: 8px;
  top: 8px;
}
#profile_Group_2 {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#profile_Rectangle_26_s {
  fill: rgba(162, 228, 197, 1);
}
.profile_Rectangle_26_s {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#profile_Update_t {
  left: 28px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 56px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#profile_Group_3_profile:hover {
  left: 197px;
  top: 8px;
}
#profile_Group_3_profile {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 189px;
  top: 0px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#profile_Rectangle_27_y {
  fill: rgba(162, 228, 197, 1);
}
.profile_Rectangle_27_y {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#profile_Change_Password_z {
  left: 15px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 133px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Group_12:hover {
  left: 248px;
  top: 1350px;
}
#Group_12 {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 240px;
  top: 1342px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#profile_Group_12:hover {
  left: 97px;
  top: 77px;
}
#profile_Group_12 {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 89px;
  top: 69px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#profile_Rectangle_27_bb {
  fill: rgba(162, 228, 197, 1);
}
.profile_Rectangle_27_bb {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#profile_Register_bb {
  left: 51px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#profile_Group_9 {
  position: fixed;
  width: 125px;
  height: 50px;
  right: 31px;
  top: 18px;
  overflow: visible;
}
#profile_notification-bell {
  position: absolute;
  width: 37px;
  height: 38px;
  left: 0px;
  top: 7px;
  overflow: visible;
}
#profile_Group_7 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 496px;
  top: 0px;
  overflow: visible;
  border-radius: 50px;
}
#profile_Ellipse_1 {
  fill: rgba(255, 255, 255, 1);
}
.profile_Ellipse_1 {
  position: absolute;
  overflow: visible;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#profile_home1 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10px;
  top: 10px;
  overflow: visible;
}
#profile_Group_8 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 551px;
  top: 0px;
  overflow: visible;
  border-radius: 50px;
}
#profile_Ellipse_1_bl {
  fill: rgba(255, 255, 255, 1);
}
.profile_Ellipse_1_bl {
  position: absolute;
  overflow: visible;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#profile_logout1 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10px;
  top: 10px;
  overflow: visible;
}
#profile_logo {
  position: fixed;
  width: 118px;
  height: 118px;
  left: 24px;
  top: 23px;
  overflow: visible;
}
#profile_employee_grp {
  position: absolute;
  width: 283px;
  height: 71px;
  left: 30px;
  top: 210px;
  overflow: visible;
}
#profile_Rectangle_2 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_2 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 0px;
  top: 22px;
  font-size: 17px;
  padding: 10px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Employees_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 82px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_Email_grp {
  position: absolute;
  width: 284px;
  height: 71px;
  left: 340px;
  top: 210px;
  overflow: visible;
}
#profile_Rectangle_3 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_3 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 1px;
  top: 22px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Email_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 41px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_UID_grp {
  position: absolute;
  width: 283px;
  height: 72px;
  left: 30px;
  top: 289px;
  overflow: visible;
}
#profile_Rectangle_4 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_4 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 50px;
  left: 0px;
  top: 22px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Unique_ID_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 76px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_DOJ_grp {
  position: absolute;
  width: 284px;
  height: 72px;
  left: 340px;
  top: 289px;
  overflow: visible;
}
#profile_Rectangle_5 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_5 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 50px;
  left: 1px;
  top: 22px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Date_of_Joining_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 114px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_Mob_grp {
  position: absolute;
  width: 283px;
  height: 71px;
  left: 30px;
  top: 367px;
  overflow: visible;
}
#profile_Rectangle_6 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_6 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 0px;
  top: 22px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Mobile_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 53px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_gender_grp {
  position: absolute;
  width: 284px;
  height: 71px;
  left: 340px;
  top: 367px;
  overflow: visible;
}
#profile_Rectangle_7 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_7 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 1px;
  top: 22px;
  font-size: 17px;
  padding: 10px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Gender_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 56px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_DOB_GRP {
  position: absolute;
  width: 283px;
  height: 72px;
  left: 30px;
  top: 444px;
  overflow: visible;
}
#profile_Rectangle_8 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_8 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 50px;
  left: 0px;
  top: 22px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_DOB_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 35px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_Destination {
  position: absolute;
  width: 284px;
  height: 72px;
  left: 340px;
  top: 444px;
  overflow: visible;
}
#profile_Rectangle_9 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_9 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 50px;
  left: 1px;
  top: 22px;
  font-size: 17px;
  padding: 10px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Designation_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 91px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_reprt_grp {
  position: absolute;
  width: 283px;
  height: 70px;
  left: 30px;
  top: 523px;
  overflow: visible;
}
#profile_Rectangle_10 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_10 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 0px;
  top: 21px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
.profile_Rectangle_10_profile_select {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 0px;
  top: 21px;
  font-size: 17px;
  padding: 10px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Reporting_To_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 97px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_access_grp {
  position: absolute;
  width: 284px;
  height: 70px;
  left: 340px;
  top: 523px;
  overflow: visible;
}
#profile_Rectangle_11 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_11 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 1px;
  top: 21px;
  font-size: 17px;
  padding: 10px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Access_Type_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 91px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_pan_grp {
  position: absolute;
  width: 283px;
  height: 72px;
  left: 30px;
  top: 598px;
  overflow: visible;
}
#profile_Rectangle_12 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_12 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_PAN_Number_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 99px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_passport_grp {
  position: absolute;
  width: 284px;
  height: 72px;
  left: 340px;
  top: 598px;
  overflow: visible;
}
#profile_Rectangle_13 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_13 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 1px;
  top: 23px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Passport_Expiry_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 154px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_emergency_grp {
  position: absolute;
  width: 283px;
  height: 72px;
  left: 30px;
  top: 675px;
  overflow: visible;
}
#profile_Rectangle_14 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_14 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 0px;
  top: 23px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Emergency_Contact_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 202px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_emer_number_grp {
  position: absolute;
  width: 284px;
  height: 72px;
  left: 340px;
  top: 675px;
  overflow: visible;
}
#profile_Rectangle_15 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_15 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 1px;
  top: 23px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Emergency_Contact_profile_ct {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 213px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_emer_person_grp {
  position: absolute;
  width: 283px;
  height: 69px;
  left: 30px;
  top: 755px;
  overflow: visible;
}
#profile_upi_grp {
  position: absolute;
  width: 283px;
  height: 69px;
  left: 30px;
  top: 1190px;
  overflow: visible;
}
#profile_Rectangle_16 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_16 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 0px;
  top: 20px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
.profile_Rectangle_upi_16 {
  position: absolute;
  overflow: visible;
  width: 584px;
  height: 49px;
  left: 0px;
  top: 20px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Emergency_Contact_profile_cw {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 202px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_emer_grp {
  position: absolute;
  width: 284px;
  height: 69px;
  left: 340px;
  top: 755px;
  overflow: visible;
}
#profile_Rectangle_17 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_17 {
  position: absolute;
  overflow: visible;
  width: 283px;
  height: 49px;
  left: 1px;
  top: 20px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Emergency_Contact_profile_cz {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 213px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_address_grp {
  position: absolute;
  width: 584px;
  height: 140px;
  left: 30px;
  top: 828px;
  overflow: visible;
}
#profile_Rectangle_18 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_18 {
  position: absolute;
  overflow: visible;
  width: 584px;
  height: 116px;
  left: 0px;
  top: 24px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Address_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_bank_grp {
  position: absolute;
  width: 584px;
  height: 140px;
  left: 30px;
  top: 972px;
  overflow: visible;
}
#profile_Rectangle_19 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_19 {
  position: absolute;
  overflow: visible;
  width: 584px;
  height: 116px;
  left: 0px;
  top: 24px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Bank_Accountprofile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 159px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_SSH_grp_1 {
  position: absolute;
  width: 141px;
  height: 73px;
  left: 30px;
  top: 1115px;
  overflow: visible;
}
#profile_Rectangle_21 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_21 {
  position: absolute;
  overflow: visible;
  width: 141px;
  height: 54px;
  left: 0px;
  top: 19px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Shift_Start_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 124px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_SSM_grp1 {
  position: absolute;
  width: 142px;
  height: 73px;
  left: 175px;
  top: 1115px;
  overflow: visible;
}
#profile_Rectangle_22 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_22_profile {
  position: absolute;
  overflow: visible;
  width: 142px;
  height: 54px;
  left: 0px;
  top: 19px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Shift_Startprofile {
  left: 1px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 139px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_SSH_grp_2 {
  position: absolute;
  width: 143px;
  height: 73px;
  left: 330px;
  top: 1115px;
  overflow: visible;
}
#profile_Rectangle_23_profile {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_23_profile {
  position: absolute;
  overflow: visible;
  width: 142px;
  height: 54px;
  left: 1px;
  top: 19px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Shift_Stop_profile {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 116px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#profile_SSM_grp_2 {
  position: absolute;
  width: 141px;
  height: 73px;
  left: 475px;
  top: 1115px;
  overflow: visible;
}
#profile_Rectangle_24 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.profile_Rectangle_24 {
  position: absolute;
  overflow: visible;
  width: 141px;
  height: 54px;
  left: 0px;
  top: 19px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#profile_Shift_Stop_Minutes {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 139px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}

#Rectangle_Password {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_Password {
  position: relative;
  overflow: visible;
  width: 283px;
  height: 54px;
  left: 0px;
  /* top: 1096px; */
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}