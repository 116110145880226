.mediaViewInfo {
  --web-view-name: task pop-up;
  --web-view-id: task_pop-up;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: task_pop-up;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#task_pop-up {
  z-index: 1;
  position: fixed;
  width: 615px;
  height: 579px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  --web-view-name: task pop-up;
  --web-view-id: task_pop-up;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#close_task {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 576px;
  top: 7px;
  overflow: visible;
  cursor: pointer;
}
#update_task_bg_design {
  position: absolute;
  width: 618px;
  height: 562px;
  left: 0px;
  top: 17px;
  overflow: visible;
}
#btn_grp:hover {
  left: 233px;
  top: 520px;
}
#btn_grp {
  position: absolute;
  width: 162px;
  height: 50px;
  left: 228px;
  top: 515px;
  overflow: visible;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
#update_task_leave {
  fill: rgba(162, 228, 197, 1);
}
.update_task_leave {
  position: absolute;
  overflow: visible;
  width: 162px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#update_task_lbl {
  left: 36px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 92px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Top_bdr_grp {
  position: absolute;
  width: 618px;
  height: 50px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#update_task_top_p {
  fill: url(#update_task_top_p);
}
.update_task_top_p {
  position: absolute;
  overflow: visible;
  width: 618px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Update_Task_top_lbl {
  left: 256px;
  top: 12px;
  position: absolute;
  overflow: visible;
  width: 108px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(37, 37, 37, 1);
}
#pop_task_scroll_ {
  mix-blend-mode: normal;
  position: absolute;
  width: 618px;
  height: 446px;
  left: 0px;
  top: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
}
#assigned_by_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.assigned_by_input {
  position: absolute;
  overflow: visible;
  width: 188px;
  height: 42px;
  left: 15px;
  top: 30px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 5px;
}
#Task_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Task_input {
  position: absolute;
  overflow: visible;
  width: 514px;
  height: 42px;
  left: 52px;
  top: 100px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 5px;
}
#details_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.details_input {
  position: absolute;
  overflow: visible;
  width: 514px;
  height: 80px;
  left: 52px;
  top: 167px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 5px;
}
#assigned_on {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.assigned_on {
  position: absolute;
  overflow: visible;
  width: 187px;
  height: 42px;
  left: 216px;
  top: 30px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#assigned_to_ {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.assigned_to_ {
  position: absolute;
  overflow: visible;
  width: 187px;
  height: 42px;
  left: 416px;
  top: 30px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#deadline_date {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.deadline_date {
  position: absolute;
  overflow: visible;
  width: 187px;
  height: 42px;
  left: 180px;
  top: 273px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#status_select {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.status_select {
  position: absolute;
  overflow: visible;
  width: 187px;
  height: 42px;
  left: 380px;
  top: 273px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#Important_grp {
  position: absolute;
  width: 72px;
  height: 16px;
  left: 71px;
  top: 269px;
  overflow: visible;
}
#imp_checkbox_4 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.imp_checkbox_4 {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#Imp_4_lbl {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 54px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#urgent_grp_ {
  position: absolute;
  width: 56px;
  height: 16px;
  left: 71px;
  top: 295px;
  overflow: visible;
}
#urgent_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.urgent_input {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#urgent_3lbl {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 38px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#add_com_bdr_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.add_com_bdr_input {
  position: absolute;
  overflow: visible;
  width: 514px;
  height: 80px;
  left: 52px;
  top: 341px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 5px;
}
#com_bdr_input {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.com_bdr_input {
  position: absolute;
  overflow: visible;
  width: 514px;
  height: 80px;
  left: 53px;
  top: 446px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 5px;
}
#Assigned_by_taskpop {
  left: 18px;
  top: 2px;
  position: absolute;
  overflow: visible;
  width: 92px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Assigned_on_taskpop {
  left: 216px;
  top: 2px;
  position: absolute;
  overflow: visible;
  width: 93px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Assigned_To_taskpop {
  left: 416px;
  top: 2px;
  position: absolute;
  overflow: visible;
  width: 91px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Task_taskpop {
  left: 54px;
  top: 78px;
  position: absolute;
  overflow: visible;
  width: 32px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Details_taskpop {
  left: 54px;
  top: 144px;
  position: absolute;
  overflow: visible;
  width: 52px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Deadline_taskpop {
  left: 180px;
  top: 249px;
  position: absolute;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Status_taskpop {
  left: 380px;
  top: 249px;
  position: absolute;
  overflow: visible;
  width: 46px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Comments_taskpop {
  left: 54px;
  top: 317px;
  position: absolute;
  overflow: visible;
  width: 82px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
#Add_Comments_taskpop {
  left: 54px;
  top: 424px;
  position: absolute;
  overflow: visible;
  width: 118px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}
