#mid_changes_salary {
  position: absolute;
  width: 411.862px;
  height: 218.348px;
  left: 233px;
  top: 44px;
  overflow: visible;
}
#bdr_2 {
  fill: rgba(255, 255, 255, 1);
}
.bdr_2 {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 29px;
  top: 167.374px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_1 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_1 {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 33px;
  top: 171px;
}
#play-buttton_img {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 41px;
  top: 179px;
  overflow: visible;
}
#link_up {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 41.917px;
  top: 160px;
  overflow: visible;
}
#link_down {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 42.126px;
  top: 201.6px;
  overflow: visible;
}
#mid_grp_ {
  position: absolute;
  width: 43px;
  height: 43px;
  left: 368.861px;
  top: 167.374px;
  overflow: visible;
}
#bdr_back {
  fill: rgba(255, 255, 255, 1);
}
.bdr_back {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 4px;
  top: 4px;
}
#link1_mid_top {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 381.779px;
  top: 160px;
  overflow: visible;
}
#link1_mid_down {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 381.987px;
  top: 201.6px;
  overflow: visible;
}
#Inputs_and_check_box_grp_op {
  position: absolute;
  width: 399px;
  height: 115px;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.component_input_OP {
  position: absolute;
  overflow: visible;
  width: 193px;
  height: 43px;
  left: 0px;
  top: 21px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
.salry_componet_ip {
  overflow: visible;
  width: 193px;
  height: 43px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
.subcomponent_input_OP {
  position: absolute;
  overflow: visible;
  width: 193px;
  height: 43px;
  left: 0px;
  top: 100px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#percentage_lbl_grp {
  position: absolute;
  width: 120px;
  height: 22px;
  left: 273px;
  top: 0px;
  overflow: visible;
}
#checkbox_percentage {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.checkbox_percentage {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 15px;
  left: 0px;
  top: 4px;
  --web-animation: fadein undefineds undefined;
  --web-action-type: page;
  --web-action-target: new_page.html;
  cursor: pointer;
}
#Is_Percentage_lbl {
  left: 20px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 101px;
  white-space: nowrap;
  --web-animation: fadein undefineds undefined;
  --web-action-type: page;
  --web-action-target: new_page.html;
  cursor: pointer;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(16, 16, 16, 1);
}
#compulsory_lbl_grp {
  position: absolute;
  width: 126px;
  height: 22px;
  left: 273px;
  top: 47px;
  overflow: visible;
}
#compulsory_chxkbx {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.compulsory_chxkbx {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 15px;
  left: 0px;
  top: 4px;
}
#Is_Compulsory_lbl {
  left: 20px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 107px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(16, 16, 16, 1);
}
#Deduction_lbl_grp {
  position: absolute;
  width: 114px;
  height: 22px;
  left: 273px;
  top: 93px;
  overflow: visible;
}
#checkbox_ded {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.checkbox_ded {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 15px;
  left: 0px;
  top: 4px;
}
#Is_Deduction_lbl {
  left: 20px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 95px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(16, 16, 16, 1);
}
#component_lbl_ {
  left: 1px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 85px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(16, 16, 16, 1);
}
#subcomponent_lbl_ {
  left: 1px;
  top: 78px;
  position: absolute;
  overflow: visible;
  width: 85px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(16, 16, 16, 1);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 85%;
  border: 1px solid #ddd;
  margin: 10px auto;
}

th,
td {
  text-align: left;
  padding: 8px;
}
#payroll_btn {
  position: absolute;
  width: 135px;
  height: 22px;
  left: 473px;
  top: 89px;
  overflow: visible;
}
