#details_input_task_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.details_input_task_mob {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 58px;
  left: 42px;
  top: 57px;
  font-size: 13px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#comment_box_task_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.comment_box_task_mob {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 34px;
  left: 42px;
  top: 16px;
  font-size: 15px;
  padding: 5px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#assign_grp_mob {
  position: absolute;
  width: 218px;
  height: 54px;
  left: 42px;
  top: 118px;
  overflow: visible;
}
#assign_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.assign_input_mob {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 34px;
  left: 0px;
  top: 20px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#assign_lbl_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 64px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#dead_grp_task_mob {
  position: absolute;
  width: 218px;
  height: 54px;
  left: 42px;
  top: 176px;
  overflow: visible;
}
#deadline_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.deadline_input_mob {
  position: absolute;
  overflow: visible;
  width: 218px;
  height: 34px;
  left: 0px;
  top: 20px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#deadline_lbl_task_mob {
  left: 2px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 59px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(37, 37, 37, 1);
}
#checkbox_overall_grp_mob {
  position: absolute;
  width: 169px;
  height: 20px;
  left: 67px;
  top: 238px;
  overflow: visible;
}
#imp_checkbox_mob {
  position: absolute;
  width: 84px;
  height: 20px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Important_task_mob {
  left: 18px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#chkbx_task_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_task_mob {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 0px;
  top: 2px;
}
#urgent_chckbx_mob {
  position: absolute;
  width: 64px;
  height: 20px;
  left: 105px;
  top: 0px;
  overflow: visible;
}
#Urgent_task_mob {
  left: 18px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 47px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#chkbx_task_1_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_task_1_mob {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 0px;
  top: 2px;
}
#mid_btn_grp_mob {
  position: absolute;
  width: 129px;
  height: 55px;
  left: 87px;
  top: 273px;
  overflow: visible;
}
#create_task_btn_grp_mob {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 4px;
  top: 20px;
  overflow: visible;
}
#create_bdr_task_mob {
  fill: rgba(255, 255, 255, 1);
}
.create_bdr_task_mob {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#play-butttontask_mob {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 5px;
  top: 5.5px;
  overflow: visible;
}
#reset_task_btn_grp_mob {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 93px;
  top: 20px;
  overflow: visible;
}
#stop-button_bdrtask_mob {
  fill: rgba(255, 255, 255, 1);
}
.stop-button_bdrtask_mob {
  filter: drop-shadow(7px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
}
#stop-button_imgtask_mob {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 5px;
  top: 6px;
  overflow: visible;
}
#task_Create_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 44px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#task_Reset_mob {
  left: 93px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 37px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#card_1_task_bar {
  position: relative;
  width: 282px;
  height: 97px;
  left: 10px;
  top: 7px;
  overflow: visible;
  margin-bottom: 10px;
}
#card_bg_task_mob {
  fill: rgba(255, 255, 255, 1);
}
.card_bg_task_mob {
  position: absolute;
  overflow: visible;
  width: 282px;
  height: 97px;
  left: 0px;
  top: 0px;
}
#name_lbl_task_mob {
  left: 9px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 121px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#date_task_mob {
  left: 196px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 78px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#time_task_mob {
  left: 28px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 63px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#to-do-list_task_mob {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 9px;
  top: 27px;
  overflow: visible;
}
#priority_task_mob {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 11px;
  top: 72px;
  overflow: visible;
}
#time_task_mob_cj {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 11px;
  top: 49px;
  overflow: visible;
}
#lbl_time_task_mob {
  left: 31px;
  top: 51px;
  position: absolute;
  overflow: visible;
  width: 72px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#lbl_task_mob {
  left: 28px;
  top: 71px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}

#lbl_task_details {
  left: 110px;
  top: 19px;
  position: absolute;
  overflow: visible;
  width: 174px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#Line_1_task_mob {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_1_task_mob {
  overflow: visible;
  position: absolute;
  width: 266px;
  height: 1px;
  left: 8.5px;
  top: 18.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

/* popup css */

#task_pop_mob {
  position: absolute;
  width: 288px;
  height: 606px;
  overflow: hidden;
  left: 41px;
  top: 34px;
}
#over_all_task_pop_grp {
  position: absolute;
  width: 288px;
  height: 608px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#bg_design_mob_tasks {
  position: absolute;
  width: 288px;
  height: 558px;
  left: 0px;
  top: 50px;
  overflow: visible;
}
#Top_bdr_grp_mob {
  position: absolute;
  width: 288px;
  height: 50px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#update_task_top_mob_l {
  fill: url(#update_task_top_mob_l);
}
.update_task_top_mob_l {
  position: absolute;
  overflow: visible;
  width: 288px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#Update_Task_top_lbl_mob {
  left: 99px;
  top: 14px;
  position: absolute;
  overflow: visible;
  width: 92px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#input_btn_grp {
  position: absolute;
  width: 288px;
  height: 504px;
  left: 0px;
  top: 60px;
  overflow: visible;
}
#scroll_uoddate_task_grp {
  mix-blend-mode: normal;
  position: absolute;
  width: 288px;
  height: 424px;
  left: 0px;
  top: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
}
#assigned_grp_by_mob {
  position: absolute;
  width: 232px;
  height: 62px;
  left: 28px;
  top: 0px;
  overflow: visible;
}
#assigned_by_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.assigned_by_input_mob {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 5px;
}
#Assigned_by_taskpop_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 81px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#assigned_on_mob_grp {
  position: absolute;
  width: 232px;
  height: 62px;
  left: 28px;
  top: 72px;
  overflow: visible;
}
#assigned_on_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.assigned_on_mob {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#Assigned_on_taskpop_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 82px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#assigned_to_mob_grp {
  position: absolute;
  width: 232px;
  height: 62px;
  left: 28px;
  top: 144px;
  overflow: visible;
}
#assigned_to_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.assigned_to_mob {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#Assigned_To_taskpop_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 80px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#task_grp_mob {
  position: absolute;
  width: 232px;
  height: 88px;
  left: 28px;
  top: 216px;
  overflow: visible;
}
#Task_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Task_input_mob {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 68px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 5px;
}
#Task_taskpop_mob {
  left: 4px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 29px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#details_grp_mob {
  position: absolute;
  width: 232px;
  height: 100px;
  left: 28px;
  top: 314px;
  overflow: visible;
}
#details_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.details_input_mob {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 80px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 5px;
}
#Details_taskpop_mob {
  left: 2px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 46px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#deadline_grp_mob {
  position: absolute;
  width: 110px;
  height: 62px;
  left: 28px;
  top: 424px;
  overflow: visible;
}
#deadline_date_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.deadline_date_mob {
  position: absolute;
  overflow: visible;
  width: 110px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#Deadline_taskpop_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 59px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#status_grp_mob {
  position: absolute;
  width: 110px;
  height: 62px;
  left: 150px;
  top: 424px;
  overflow: visible;
}
#status_select_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.status_select_mob {
  position: absolute;
  overflow: visible;
  width: 110px;
  height: 42px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding-left: 3px;
}
#Status_taskpop_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 41px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#Important_grp_mob {
  position: absolute;
  width: 72px;
  height: 16px;
  left: 73px;
  top: 498px;
  overflow: visible;
}
#imp_checkbox_4_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.imp_checkbox_4_mob {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#Imp_4_lbl_mob {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 54px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#urgent_grp__mob {
  position: absolute;
  width: 56px;
  height: 16px;
  left: 159px;
  top: 498px;
  overflow: visible;
}
#urgent_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.urgent_input_mob {
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 16px;
  left: 0px;
  top: 0px;
}
#urgent_3lbl_mob {
  left: 19px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 38px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(37, 37, 37, 1);
}
#comment_mob {
  position: absolute;
  width: 232px;
  height: 101px;
  left: 28px;
  top: 524px;
  overflow: visible;
}
#add_com_bdr_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.add_com_bdr_input_mob {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 80px;
  left: 0px;
  top: 21px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 5px;
}
#Comments_taskpop_mob {
  left: 2px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#com_add_mob {
  position: absolute;
  width: 232px;
  height: 100px;
  left: 28px;
  top: 635px;
  overflow: visible;
}
#com_bdr_input_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.com_bdr_input_mob {
  position: absolute;
  overflow: visible;
  width: 232px;
  height: 80px;
  left: 0px;
  top: 20px;
  border-radius: 10px;
  font-size: 15px;
  border: 2px solid darkcyan;
  padding: 5px;
}
#Add_Comments_mob {
  left: 1px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 104px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#btn_grp_mob {
  position: absolute;
  width: 133px;
  height: 50px;
  left: 78px;
  top: 454px;
  overflow: visible;
  border-radius: 10px;
}
#update_task_leave_mob {
  fill: rgba(162, 228, 197, 1);
}
.update_task_leave_mob {
  position: absolute;
  overflow: visible;
  width: 133px;
  height: 50px;
  left: 0px;
  top: 0px;
}
#update_task_lbl_mob {
  left: 27px;
  top: 16px;
  position: absolute;
  overflow: visible;
  width: 81px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#close_task_mob_popup {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 155px;
  top: -4px;
  overflow: visible;
  cursor: pointer;
}
