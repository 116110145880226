#task_pop_up {
  position: absolute;
  width: 298px;
  height: 541px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: task pop up;
  --web-view-id: task_pop_up;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#Group_38 {
  position: absolute;
  width: 299px;
  height: 541px;
  left: -1px;
  top: 0px;
  overflow: visible;
}
#full_bg_design_holi_u {
  fill: url(#full_bg_design_holi_u);
}
.full_bg_design_holi_u {
  position: absolute;
  overflow: visible;
  width: 299px;
  height: 541px;
  left: 0px;
  top: 0px;
}
#Group_37 {
  position: absolute;
  width: 236px;
  height: 107.078px;
  left: 34px;
  top: 11px;
  overflow: visible;
}
#employee_bdr_input_holi {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.employee_bdr_input_holi {
  position: absolute;
  overflow: visible;
  width: 236px;
  height: 36.374px;
  left: 0px;
  top: 17px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#Employee_lbl_holi {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#to_bdr_input_holi {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.to_bdr_input_holi {
  position: absolute;
  overflow: visible;
  width: 236px;
  height: 36.374px;
  left: 0px;
  top: 70.704px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#To_lbl_holi {
  left: 0px;
  top: 53.704px;
  position: absolute;
  overflow: visible;
  width: 63px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(37, 37, 37, 1);
}
#Group_22_mob {
  position: absolute;
  width: 45px;
  height: 45px;
  left: 126px;
  top: 214px;
  overflow: visible;
}
#Ellipse_4 {
  fill: rgba(255, 255, 255, 1);
}
.Ellipse_4 {
  filter: drop-shadow(7px 5px 5px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 60px;
  height: 60px;
  left: 0px;
  top: 0px;
}
#search1 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 8px;
  top: 8px;
  overflow: visible;
}
