#menu_grp {
  position: absolute;
  width: 138px;
  height: calc(100vh - 245px);
  left: 20px;
  top: 255px;
  overflow: visible;
}
#full_bg_menu {
  fill: rgba(255, 255, 255, 1);
  height: calc(100vh - 300px);
}
.full_bg_menu {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: calc(100vh - 260px);
    left: 0px;
    top: 0px;
    box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
    border-radius: 15px;
}
#Attendance_lbl {
  left: 41px;
  top: 22px;
  position: absolute;
  overflow: visible;
  width: 92px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#immigration_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 19px;
  overflow: visible;
}
#Holidays_lbl {
  left: 45px;
  top: 297px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#holiday_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 294px;
  overflow: visible;
}
#Leaves_lbl {
  left: 45px;
  top: 78px;
  position: absolute;
  overflow: visible;
  width: 54px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#sick_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 75px;
  overflow: visible;
}
#Messages_lbl {
  left: 43px;
  top: 189px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#msg_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 186px;
  overflow: visible;
}
#Approval_lbl {
  left: 43px;
  top: 245px;
  position: absolute;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#sick_img_bc {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 241px;
  overflow: visible;
}
#Tasks_lbl {
  left: 45px;
  top: 134px;
  position: absolute;
  overflow: visible;
  width: 42px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#salary_lbl {
  left: 45px;
  top: 289px;
  position: absolute;
  overflow: visible;
  width: 42px;
  white-space: normal;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#task_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 130px;
  overflow: visible;
}
#salary_img {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 283px;
  overflow: visible;
}
.active {
  border-bottom: 2px solid red;
}
