#menu {
  position: absolute;
  width: 375px;
  height: 812px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: menu;
  --web-view-id: menu;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
  z-index: 1;
}
#bg_design_mob {
  position: absolute;
  width: 375px;
  height: 812px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#menu_grp_ {
  position: absolute;
  width: 138px;
  height: 462px;
  left: 119px;
  top: 175px;
  overflow: visible;
}
#full_bg_menu_mob {
  fill: rgba(255, 255, 255, 1);
}

_mob {
  position: absolute;
  overflow: visible;
  width: 138px;
  height: 462px;
  left: 0px;
  top: 0px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 15px;
}
#Attendance_lbl_mob {
  left: 41px;
  top: 22px;
  position: absolute;
  overflow: visible;
  width: 92px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#immigration_img_mob {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 19px;
  overflow: visible;
}
#Holidays_lbl_mob {
  left: 45px;
  top: 408px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#holiday_img_mob {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 405px;
  overflow: visible;
}
#Leaves_lbl_mob {
  left: 45px;
  top: 104px;
  position: absolute;
  overflow: visible;
  width: 54px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#sick_img_mob {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 101px;
  overflow: visible;
}
#Messages_lbl_mob {
  left: 43px;
  top: 253px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#msg_img_mob {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 250px;
  overflow: visible;
}
#Approval_lbl_mob {
  left: 43px;
  top: 328px;
  position: absolute;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#sick_img_mob_ {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 324px;
  overflow: visible;
}
#Tasks_lbl_mob {
  left: 45px;
  top: 179px;
  position: absolute;
  overflow: visible;
  width: 42px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(37, 37, 37, 1);
}
#task_img_mob {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 7px;
  top: 175px;
  overflow: visible;
}
#back_card2_mob {
  fill: rgba(219, 218, 164, 1);
}
.back_card2_mob {
  position: absolute;
  overflow: visible;
  width: 48px;
  height: 655px;
  left: 17px;
  top: 115px;
}
#front_card_mob_1 {
  position: absolute;
  width: 40px;
  height: 727px;
  left: -7px;
  top: 43px;
  overflow: visible;
}
@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation: slideInLeft 1s;
}
@keyframes slideInLeft1 {
  from {
    transform: translate3d(-300%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft1 {
  animation: slideInLeft 1s;
}
