#Group_3 {
  position: absolute;
  width: 811px;
  height: 247.348px;
  left: 59px;
  top: 15px;
  overflow: visible;
}
#comment_box_task {
  fill: rgba(255, 255, 255, 1);
}
.comment_box_task {
  position: absolute;
  overflow: visible;
  width: 586px;
  height: 43px;
  left: 0px;
  top: 0px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#comment_box_task_b {
  fill: rgba(255, 255, 255, 1);
}
.comment_box_task_b {
  position: absolute;
  overflow: visible;
  width: 586px;
  height: 99px;
  left: 0px;
  top: 68px;
  font-size: 17px;
  padding: 15px;
  border: 2px solid darkcyan;
  border-radius: 10px;
}
#app_res_grp_task {
  position: absolute;
  width: 383.862px;
  height: 80.348px;
  left: 202px;
  top: 167px;
  overflow: visible;
}
#Start_Session_lbl_task {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Session_End_lbl_task {
  left: 342px;
  top: 1px;
  position: absolute;
  overflow: visible;
  width: 41px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#bdr_task {
  fill: rgba(255, 255, 255, 1);
}
.bdr_task {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 1px;
  top: 29.374px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_task_b {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_task_b {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 5px;
  top: 33.374px;
}
#play-buttton_img_task {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 10.216px;
  top: 37.798px;
  overflow: visible;
}
#link_up_task {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 13.917px;
  top: 22px;
  overflow: visible;
}
#link_down_task {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 14.126px;
  top: 63.6px;
  overflow: visible;
}
#left_link_up_task_ca {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up_task_ca {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 22.641px;
  top: 33.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down_task_cb {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down_task_cb {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 17.503px;
  top: 67.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#bdr_back_task {
  fill: rgba(255, 255, 255, 1);
}
.bdr_back_task {
  position: absolute;
  overflow: visible;
  width: 43px;
  height: 43px;
  left: 340.862px;
  top: 29.374px;
  box-shadow: 16px 16px 32px #c8c8c8, -16px -16px 32px #fefefe;
  border-radius: 50px;
}
#bdr_task_cd {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bdr_task_cd {
  position: absolute;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: 344.862px;
  top: 33.374px;
}
#stop-button_img_task {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 351.362px;
  top: 37.874px;
  overflow: visible;
}
#link1_mid_top_task {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 353.779px;
  top: 22px;
  overflow: visible;
}
#link1_mid_down_task {
  position: absolute;
  width: 16.748px;
  height: 16.748px;
  left: 353.988px;
  top: 63.6px;
  overflow: visible;
}
#left_link_up_task_ch {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_up_task_ch {
  overflow: visible;
  position: absolute;
  width: 8.525px;
  height: 2.977px;
  left: 362.502px;
  top: 33.89px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#left_link_down_task_ci {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.left_link_down_task_ci {
  overflow: visible;
  position: absolute;
  width: 4.401px;
  height: 1.645px;
  left: 357.364px;
  top: 67.026px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#assign_grp {
  position: absolute;
  width: 185px;
  height: 56px;
  left: 626px;
  top: 43px;
  overflow: visible;
}
#assign_input_ {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.assign_input_ {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 34px;
  left: 0px;
  top: 22px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#assign_lbl {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 72px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#dead_grp {
  position: absolute;
  width: 185px;
  height: 56px;
  left: 626px;
  top: 111px;
  overflow: visible;
}
#deadline_input_ {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.deadline_input_ {
  position: absolute;
  overflow: visible;
  width: 185px;
  height: 34px;
  left: 0px;
  top: 22px;
  font-size: 15px;
  border: 2px solid darkcyan;
  border-radius: 7px;
}
#deadline_lbl_task {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(37, 37, 37, 1);
}
#Important_task {
  left: 644px;
  top: 8px;
  position: absolute;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#Urgent_task {
  left: 749px;
  top: 8px;
  position: absolute;
  overflow: visible;
  width: 47px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#chkbx_task {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_task {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 626px;
  top: 10px;
}
#chkbx_task_cs {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkbx_task_cs {
  position: absolute;
  overflow: visible;
  width: 17px;
  height: 17px;
  left: 731px;
  top: 10px;
}
